<template>
    <div 
        class="w-auto h-auto p-2 flex flex-col justify-start items-center"
    >
        <dateSelect :apiLoading="apiLoading"></dateSelect>
        <div class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div class="px-2">To:</div>
            <div class="px-2">{{className}}班</div>
        </div>
        <div class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <button
                @click="warnSign"
                class="w-[auto] bg-[#483D8B] text-sm md:text-xl text-white py-1 px-2 rounded">
                提醒簽名
            </button>
        </div>
        <div 
            v-for="(item,index) in classData" :key="index"
            class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg text-[#808080] rounded-lg bg-slate-50 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full ">
                <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl" class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full" alt="">

            </div>
            <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                <div class="w-auto flex flex-wrap items-start justify-center">
                    <div >{{item.studentUserName}}</div>
                    <div class="text-black">{{item.studentNumber + '號'}}</div>
                </div>
                <div 
                    v-if="item.signature"
                    class="w-auto text-[#00FF7F] flex flex-wrap items-center justify-center">
                    已回簽
                </div>
                <div 
                    v-else
                    class="w-auto text-[#DC143C] flex flex-wrap items-center justify-center">
                    尚未回簽
                </div>
            </div>
            <div v-if="!item.signature"  class="flex items-center justify-center w-[100px] h-[100px] md:w-[100px] md:h-[100px] ">
                <img :src="item.signature"  class="transform scale-125" alt="">
            </div>
        </div>
        <Teleport to="body">
            <dialogView v-if="modalStatus">
                <template v-slot:message>
                    <div class="w-full h-full py-1 px-2 md:py-2 md:px-4 flex flex-col items-center justify-center">
                        <div class=" text-base md:text-2xl font-bold px-3">確定提醒家長簽名?</div>
                        <div class=" text-sm md:text-lg px-3">{{'提醒對象:(共' + noSignList.length + '位尚未簽名)'}}</div>
                        <div class="overflow-hidden w-full h-[40%] py-1 px-2 md:py-2 md:px-4 flex flex-wrap items-center justify-center">
                            <div 
                                v-for="(item,index) in noSignList" :key="index"
                                class="text-sm md:text-lg px-1">{{item.studentUserName+'家長'}}
                            </div>
                        </div>
                    </div>
                    
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 text-sm md:text-xl flex flex-wrap justify-end items-center">
                        <button
                        @click="confirm"

                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            確定
                        </button>
                        <button
                            @click="cancel"
                            class="min-w-[20%] bg-slate-400 hover:bg-slate-500 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </dialogView>
        </Teleport>
        
    </div>
</template>

<script setup>
/*eslint-disable*/
import { ref,computed,watch,provide } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import dialogView from "@/components/dialogView.vue"
import dateSelect from '@/components/dateSelect.vue'
import { getContactBookRecordByClassId  } from '@/api/api'
import signalR from '@/utils/signalR'

const store = useStore()
const router = useRouter()
const route = useRoute()

const isMobile = computed(() => {
    return store.state.isMobile
})

const className = computed(() => {
    return localStorage.getItem('className')
})
const classId = computed(() => {
    return localStorage.getItem('classId')
})
const dayData = ref(new Date())

const apiLoading = ref(false)
const getList = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())

    await getContactBookRecordByClassId(formData).then((res) => {
        if (res.data.status) {
            classData.value = res.data.data
            console.log(classData.value)

        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
const classData = ref([
])
const noSignList = computed(() => {
    return classData.value.filter((item)=>!item.signature)
})

const init = async() => {
    if(apiLoading.value) return false

    apiLoading.value = true
    await getList()

    apiLoading.value = false
}

init()

const modalStatus = ref(false)
const confirm = () => {
    for (let i = 0; i < noSignList.value.length; i++) {
        let data = 'studentId=' + noSignList.value[i].studentId + '&classId=' + classId.value+ '&contactBookRecordId=' + noSignList.value[i].id
        signalR.sendNotify(2, data)
    }
    modalStatus.value = false
}

const cancel = () => {
    modalStatus.value = false
}
provide('cancel', cancel)

const warnSign = () => {
    modalStatus.value = true
}

</script>