<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>To:</div>
            <div class="px-2">{{ className }}班</div>
            <div>{{ studentName }} {{ studentNumber }}號</div>
        </div>
        <template v-if="!apiLoading">
            <div
                class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg my-1 px-1 py-2 
                    shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] items-center justify-end">
                <div class="w-full my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold pl-3">
                    學習狀況
                </div>
                <div
                    class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start">
                    <div class="relative w-full h-full text-sm md:text-lg">
                        <template v-for="(item, index) in studentStateRecordData" :key="index">
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-black flex flex-wrap items-center justify-start">
                                <div class="px-[1px] flex flex-wrap items-center justify-center">
                                    {{ item.value + ': ' + item.itemValue }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-gray-500 flex flex-wrap items-center justify-start">
                        <input class="w-4 h-4 md:w-5 md:h-5 mr-2" type="checkbox"
                            v-model="contactBookRecordData1.isReadStudentState"
                            :disabled="isLocked.isReadStudentState" />
                        <label>上述內容是否閱讀完畢(已讀)</label>
                    </div>
                    <button @click="read(2)"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                        確認
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { getContactBookRecordByClassIdAndStudentId, getStudentStateRecordByContactBookRecordId, editContactBookRecordByRead2 } from '@/api/api'
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import dateSelect from '@/components/dateSelect.vue'
/*eslint-disable*/
const router = useRouter()
const store = useStore()
const apiLoading = ref(false)
const classId = computed(() => {
    return localStorage.getItem('classId')
})

const studentId = computed(() => {
    return localStorage.getItem('studentId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const studentName = computed(() => {
    return localStorage.getItem('studentName')
})

const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})
const isSchool = computed(() => {
    return (user.value.roleType == 3) || (user.value.roleType == 2)
})

const contactBookRecordData = ref([{}])
const studentStateRecordData = ref([{}])
const isLocked = ref({
    isReadNotice: false,
    isReadStudentState: false
})

const contactBookRecordData1 = ref([{}])
const getContactBookRecord = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("studentId", studentId.value)
    formData.append("date", dayData.value.toDateString())
    await getContactBookRecordByClassIdAndStudentId(formData).then((res) => {
        if (res.data.status) {
            contactBookRecordData1.value = res.data.data
            isLocked.value.isReadNotice = contactBookRecordData1.value.isReadNotice
            isLocked.value.isReadStudentState = contactBookRecordData1.value.isReadStudentState
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const read = async (type) => {
    console.log("contact",contactBookRecordData1.value)
    const formData = new FormData()
    formData.append("id", contactBookRecordData1.value.id)
    formData.append("isReadNotice", contactBookRecordData1.value.isReadNotice)
    formData.append("isReadStudentState", contactBookRecordData1.value.isReadStudentState)
    await editContactBookRecordByRead2(formData).then((res) => {
        if (res.data.status) {
            alert(res.data.message)
            getContactBookRecord()
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}


const getStudentState = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("studentId", studentId.value)
    formData.append("date", dayData.value.toDateString())

    await getContactBookRecordByClassIdAndStudentId(formData).then((res) => {
        if (res.data.status) {
            contactBookRecordData.value = res.data.data
            getStudentStateRecordList()
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const getStudentStateRecordList = async () => {
    let dataList = []
    const formData = new FormData()
    formData.append("contactBookRecordId", contactBookRecordData.value.id)
    await getStudentStateRecordByContactBookRecordId(formData).then((res) => {
        if (res.data.status) {
            let studentStateRecordList = res.data.data
            let studentStateGroupList = []
            for (let index in studentStateRecordList) {
                let isHave = false
                for (let groupIndex in studentStateGroupList) {
                    if (studentStateGroupList[groupIndex].id == studentStateRecordList[index].studentStateGroupId) {
                        isHave = true
                    }
                }
                if (!isHave) {
                    let studentStateGroup = {
                        id: studentStateRecordList[index].studentStateGroupId,
                        key: studentStateRecordList[index].studentStateGroupKey,
                        value: studentStateRecordList[index].studentStateGroupValue
                    }
                    studentStateGroupList.push(studentStateGroup)
                }
            }
            let studentStateGroupList1 = []
            for (let groupIndex in studentStateGroupList) {
                let itemValue = ''
                for (let index in studentStateRecordList) {
                    if ((studentStateGroupList[groupIndex].id == studentStateRecordList[index].studentStateGroupId) &&
                        (studentStateRecordList[index].isCheck)) {
                        itemValue = itemValue + studentStateRecordList[index].studentStateItemValue + '、'
                    } else if ((studentStateGroupList[groupIndex].id == studentStateRecordList[index].studentStateGroupId) && (studentStateRecordList[index].studentStateItemType == 1)) {
                        itemValue = itemValue + studentStateRecordList[index].value + '、'
                    }
                }
                itemValue = itemValue.substring(0, itemValue.length - 1)
                let studentStateGroup = {
                    id: studentStateGroupList[groupIndex].id,
                    key: studentStateGroupList[groupIndex].key,
                    value: studentStateGroupList[groupIndex].value,
                    itemValue: itemValue !== '' ? itemValue : '無',

                }
                studentStateGroupList1.push(studentStateGroup)
            }
            studentStateRecordData.value = studentStateGroupList1
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const init = async () => {
    apiLoading.value = true
    await getStudentState()
    if (isSchool) {
        getContactBookRecord()
    }
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

</script>