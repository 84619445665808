<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-start items-center">
        <div
            class="w-full max-w-[800px] rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-center">
            <div class="w-full text-2xl md:text-4xl font-semibold">{{ className + '班' }}</div>
            <div class="w-full py-1 md:py-3 px-3 flex flex-wrap items-center justify-center">
                <div class="w-auto text-[16px] md:text-2xl">選擇類型: </div>
                <div class="w-auto px-[1px] flex flex-wrap items-center justify-center">
                    <el-select v-model="Type" placeholder="Select" @change="changeType"
                        :style="{ 'width': isMobile ? '150px' : '400px' }">
                        <el-option v-for="item in formType" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in list" :key="index" :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
            class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full">
                <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl"
                    class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full" alt="">
            </div>
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">
                    學生姓名：{{ item.studentUserName }} {{ studentNumber }}號
                </div>
            </div>
            <div v-for="(data, dataIndex) in sortedForms(item.forms)" :key="dataIndex"
                class="w-full my-2 text-left font-semibold pl-3">
                <div @click="toShow(dataIndex)">{{ getFormType(data.type) }}{{ data.name }}</div>
                <template v-if="isshow === dataIndex">
                    <div>
                        <button @click="triggerFileUpload()"
                            class="min-w-[15%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                            上傳檔案
                        </button>
                        <div v-if="data.file && data.signFile"
                            class="w-full h-full my-2 flex flex-col justify-center items-center">
                            <img :src="data.file" alt="">
                            <img :src="data.signFile" alt="" class="mt-2">
                        </div>
                        <canvas :id="`canvas${dataIndex}`" class="mt-2 border-2" width="300" height="150"></canvas>
                        <div class="w-full my-2 flex flex-row items-center gap-[10px]">
                            <button class="w-[80px] h-[40px] bg-gray-500 text-white rounded-lg"
                                @click="reset">清除</button>
                            <button class="w-[80px] h-[40px] bg-blue-500 text-white rounded-lg"
                                @click="exportFile">上傳</button>
                        </div>
                        <button @click="save(item, data)"
                            class="mt-2 min-w-[15%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                            存檔
                        </button>
                    </div>
                </template>
            </div>
        </div>
        <input type="file" ref="fileInput" v-show="false" accept="image/*,.pdf" multiple="multiple"
            data-target="file-uploader" @change="handleFiles($event, 1)" />
    </div>
</template>
<script setup>
/*eslint-disable*/
import { fabric } from 'fabric'
import { getFormRecordByStudentId, uploadFile, UpdateFormRecord, uploadSignature } from '@/api/api'
import { useStore } from "vuex";
import { ref, onMounted, computed,nextTick } from "vue";
import { useRouter } from "vue-router";

const router = useRouter()
const store = useStore()
const apiLoading = ref(false)
const list = ref([])
const Type = ref(0);
const formType = ref([
    { value: 0, label: '兒童發展檢核表' },
    { value: 1, label: '疫苗' },
])

const isMobile = computed(() => {
    return store.state.isMobile
})

const classId = computed(() => {
    return localStorage.getItem('classId')
})

const studentId = computed(() => {
    return localStorage.getItem('studentId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const studentName = computed(() => {
    return localStorage.getItem('studentName')
})

const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})

const CanvasIndex = ref(0)
const isshow = ref(null);
const toShow = async (index) => {
    if (isshow.value === index) {
        isshow.value = null;
    } else {
        CanvasIndex.value = index
        isshow.value = index;
        await nextTick();
        await initCanvas();
    }
};

let boardItem = null
const initCanvas = async () => {
    boardItem = new fabric.Canvas(`canvas${CanvasIndex.value}`, {
        isDrawingMode: true,
        backgroundColor: 'rgb(226 232 240)'
    });
    let pencilBrush = new fabric.PencilBrush(boardItem)
    boardItem.freeDrawingBrush = pencilBrush
}

onMounted(() => {
    initCanvas();
});

const getFormType = (type) => {
    const found = formType.value.find(ft => ft.value === type)
    return found ? found.label : '未知類型'
}

const ageOrder = [
    '疫苗', '4個月', '6個月', '9個月', '1歲', '1歲3個月', '1歲半',
    '2歲', '2歲半', '3歲', '3歲半', '4歲', '5歲', '6歲'
]

const sortedForms = computed(() => (forms) => {
    return [...forms].sort((a, b) => {
        const indexA = ageOrder.indexOf(a.name)
        const indexB = ageOrder.indexOf(b.name)
        if (indexA === -1) return 1
        if (indexB === -1) return -1
        return indexA - indexB
    })
})

const getList = async () => {
    const formData = new FormData()
    formData.append("studentId", studentId.value)
    formData.append("type", Type.value)
    await getFormRecordByStudentId(formData).then((res) => {
        if (res.data.status) {
            const groupedList = groupByStudent(res.data.data)
            list.value = groupedList
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const changeType = async (value) => {
    let name = ''
    for (let key in formType.value) {
        if (formType.value[key].value == value) {
            name = formType.value[key].value
            break
        }
    }
    Type.value = name;
    await getList()
}

const groupByStudent = (data) => {
    const grouped = {}
    for (const record of data) {
        if (!grouped[record.studentId]) {
            grouped[record.studentId] = {
                studentId: record.studentId,
                studentUserName: record.studentUserName,
                studentUserPictureUrl: record.studentUserPictureUrl,
                studentSchoolStudentId: record.studentSchoolStudentId,
                forms: []
            }
        }
        grouped[record.studentId].forms.push({
            id: record.id,
            formId: record.formId,
            name: record.name,
            type: record.type,
            file: record.file,
            signFile: record.signFile
        })
    }
    return Object.values(grouped).sort((a, b) =>
        a.studentSchoolStudentId.localeCompare(b.studentSchoolStudentId)
    )
}

const save = async (item, data) => {
    let formData = new FormData()
    formData.append("formId", data.formId)
    formData.append("studentId", item.studentId)
    if (file.value !== null) {
        formData.append("file", file.value)
    }
    if (signfile.value !== null) {
        formData.append("signFile", signfile.value)
    }
    try {
        if (signfile.value !== null && file.value !== null) {
            const res = await UpdateFormRecord(formData)
            if (res.data.status) {
                alert("存檔成功!!")
                await getList()
            }
            else {
                alert(res.data.message)
            }
        }
        else {
            alert("請上傳檔案以及簽名!!")
        }
    }
    catch (err) {
        alert('Error uploading file: ' + err.message)
    }
}

const file = ref(null)
const signfile = ref(null)
const handleFiles = async (event, type) => {
    let target = event.target.files
    if (!target.length) return

    let formData = new FormData()
    formData.append("file", target[0])
    formData.append("type", "form")

    try {
        const res = await uploadFile(formData)
        if (res.data.status) {
            file.value = res.data.data
            alert("上傳成功!!")
        } else {
            alert(res.data.message)
        }
    } catch (err) {
        alert('Error uploading file: ' + err.message)
    }
}

const exportFile = () => {
    let dataUrl = boardItem.toDataURL({
        format: 'png',
        quality: 1,
    });

    const formData = new FormData()
    formData.append("dataUrl", dataUrl)
    formData.append("type", "signature")
    uploadSignature(formData).then((res) => {
        if (res.data.status) {
            signfile.value = res.data.data
            alert("上傳成功!!")
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const reset = () => {
    boardItem.clear()
    boardItem.backgroundColor = 'rgb(226 232 240)'
}

const fileInput = ref(null)
const triggerFileUpload = () => {
    fileInput.value.click()
}

const init = async () => {
    if (apiLoading.value) return false
    apiLoading.value = true
    await getList()
    apiLoading.value = false
}
init()

</script>
<style lang="scss" scoped></style>