<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center" v-if="parentList.length > 0">
        <div
            v-for="(item,index) in parentList" :key="index"
            :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
            class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full ">
                <img v-if="item.userPictureUrl" :src="item.userPictureUrl" class="rounded-full " alt="">
            </div>
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">家長姓名：{{item.userName}}</div>
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">暱稱：{{item.title}}</div>
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">註解：{{item.remark}}</div>
            </div>
            <div class="w-auto px-1 h-full flex flex-col items-start justify-center">
                <button
                    @click="review(item, true)"
                    class="w-[auto] text-sm md:text-xl text-[#FF4500] mx-[2px] py-[1px] px-[2px] border-[#FF4500] border-[1px] rounded">
                    審核
                </button>
            </div>
            <div class="w-auto px-1 h-full flex flex-col items-start justify-center">
                <button
                    @click="review(item, false)"
                    class="w-[auto] text-sm md:text-xl text-[#FF4500] mx-[2px] py-[1px] px-[2px] border-[#FF4500] border-[1px] rounded">
                    清除
                </button>
            </div>
        </div>
    </div>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center" v-else>
        <div
            class="bg-slate-50 w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">尚未有綁定學生需要審核</div>
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import {ref,computed } from 'vue'
import { useRouter } from "vue-router"
import { getReviewParent, editReviewParent } from '@/api/api'

const store = useStore()
const router = useRouter()

const isMobile = computed(() => {
    return store.state.isMobile
})

const parentList = ref([])


const getList = async() => {
  await getReviewParent().then((res) => {
    if(res.data.status){
        parentList.value = res.data.data
      }else{
        console.log(res.data.message)
      }
  }).catch((err) => { })
}
getList()


const review = async(item, isReview) => {
    item.isReview = isReview;
    var formData = new FormData();
    for (var key in item) {
        if (item.hasOwnProperty(key)) {
            formData.append(key, item[key])
        }
    }    
    await editReviewParent(formData).then((res) => {
        if(res.data.status){
            getList()
        }else{
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
</script>

<style>

</style>