<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">

        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>To:</div>
            <div class="px-2">{{ className }}班</div>
            <div class="px-1 text-[#F08080]">{{ feverCount }}位發燒</div>
            <button @click="write('all')"
                class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                全部填寫
            </button>
        </div>

        <template v-if="!apiLoading">
            <div v-for="(item, index) in list" :key="index" @click="detail(item)"
                :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
                class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-between">
                <div class="flex items-center">
                    <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full ">
                        <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl" class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full"
                            alt="">
                    </div>
                    <div class="px-2 flex flex-col items-start justify-center">
                        <div class="flex flex-wrap items-start justify-center">
                            <div class="px-1">{{ item.studentUserName }}</div>
                            <div class="px-1">{{ item.studentNumber + '號' }}</div>
                            <div class="px-1" v-if="item.state != 0 && item.temperature !== 0">{{
                                item.bodyTemperatureTime.substr(0, 5) }}</div>
                        </div>
                        <div v-if="item.isParent" class="flex flex-wrap items-center justify-start gap-x-[2px]">
                            <div v-if="item.parentName && item.parentTitle">
                                {{ item.parentName + ' ' + item.parentTitle + ' 測量' }}
                            </div>
                            <div v-else-if="item.parentName">
                                {{ item.parentName + ' 測量' }}
                            </div>
                            <div v-else-if="item.parentTitle">
                                {{ item.parentTitle + ' 測量' }}
                            </div>
                        </div>
                        <div v-else class="flex flex-wrap items-center justify-start gap-x-[2px]">
                            <div v-if="item.teacherUserName && item.teacherRoleName">
                                {{ item.teacherUserName + ' ' + item.teacherRoleName + ' 測量' }}
                            </div>
                            <div v-else-if="item.teacherUserName">
                                {{ item.teacherUserName + ' 測量' }}
                            </div>
                            <div v-else-if="item.teacherRoleName">
                                {{ item.teacherRoleName + ' 測量' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-end">
                    <div class="flex flex-col items-end mb-2">
                        <div class="w-auto bg-[#4169E1] text-sm md:text-xl text-white py-[1px] px-[2px] rounded mb-1">
                            入校前測量
                        </div>
                        <div v-if="(item.state !== 0 && item.temperature !== 0)"
                            class="flex items-center justify-end gap-x-[2px]">
                            <div v-if="item.isFever"
                                class="w-auto bg-[#DC3545] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                                發燒
                            </div>
                            <div v-else
                                class="w-auto bg-[#0D6EFD] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                                正常
                            </div>
                            <div>{{ item.stateName }}</div>
                            <div>{{ item.temperature + '°C' }}</div>
                        </div>
                        <div v-else class="w-auto bg-[#999999] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                            尚未測量
                        </div>
                    </div>
                    <div class="flex flex-col items-end">
                        <div class="w-auto bg-[#4169E1] text-sm md:text-xl text-white py-[1px] px-[2px] rounded mb-1">
                            午睡後測量
                        </div>
                        <div v-if="(item.state !== 0 && item.afterTemperature !== 0)"
                            class="flex items-center justify-end gap-x-[2px]">
                            <div v-if="item.afterSleepIsFever"
                                class="w-auto bg-[#DC3545] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                                發燒
                            </div>
                            <div v-else
                                class="w-auto bg-[#0D6EFD] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                                正常
                            </div>
                            <div>{{ item.temperatureStateName }}</div>
                            <div>{{ item.afterTemperature + '°C' }}</div>
                        </div>
                        <div v-else class="w-auto bg-[#999999] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                            尚未測量
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <Teleport to="body">
            <div class="overflow-y-auto max-h-screen">
                <conversationView type="xlarge" v-if="modalStatus">
                    <template v-slot:header>
                        <div
                            class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                            <div class="w-[40px] h-[40px] md:w-[70px] md:h-[70px] bg-indigo-500 rounded-full ">
                                <img v-if="modifyData.studentUserPictureUrl" :src="modifyData.studentUserPictureUrl"
                                    class="rounded-full " alt="">
                            </div>
                            <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                                <div class="text-black">{{ modifyData.studentUserName }}</div>
                                <div>{{ modifyData.className + ' ' + modifyData.studentNumber + '號' }}</div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <div
                            class="line-style w-full text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                            測量體溫
                        </div>
                        <div class="relative w-full h-auto px-2 text-sm md:text-lg">
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <div>測量狀態</div>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <el-select v-model="modifyData.temperatureState" placeholder="請選擇類型" size="large"
                                style="width:100%;">
                                <el-option v-for="item in stateOptions_school" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <div>測量體溫時間</div>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <input v-model="modifyData.bodyTemperatureTime" required style="width:100%;" type="time">
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <div>測量體溫類型</div>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <el-select v-model="modifyData.state" placeholder="請選擇類型" size="large" style="width:100%;">
                                <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <div>體溫</div>
                        </div>
                        <div
                            class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                            <input v-model="modifyData.temperature" required style="width:100%;" type="number">
                        </div>
                    </template>
                    <template v-slot:control>
                        <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-start items-center">
                            <button @click="edit"
                                class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                                完成
                            </button>
                            <button @click="cancel"
                                class="min-w-[20%] bg-slate-400 hover:bg-slate-500 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                                取消
                            </button>
                        </div>
                    </template>
                </conversationView>
            </div>

        </Teleport>
        <Teleport to="body">
            <conversationView v-if="modalStatus2">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                            <div class="text-black">全部填寫</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>

                    <div
                        class="line-style w-full text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        測量體溫
                    </div>
                    <div class="relative w-full h-auto px-2 text-sm md:text-lg">
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <div>測量狀態</div>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <el-select v-model="modifyData2.temperatureState" placeholder="請選擇類型" size="large"
                            style="width:100%;">
                            <el-option v-for="item in stateOptions_school" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <div>測量體溫時間</div>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <input v-model="modifyData2.bodyTemperatureTime" required style="width:100%;" type="time">
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <div>測量體溫類型</div>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <el-select v-model="modifyData2.state" placeholder="請選擇類型" size="large" style="width:100%;">
                            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                                :value="item.value" :disabled="item.value === 0" />
                        </el-select>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <div>體溫</div>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                        <input v-model="modifyData2.temperature" required style="width:100%;" type="number">
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-start items-center">
                        <button @click="sendAll"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            完成
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-slate-400 hover:bg-slate-500 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
    </div>
</template>

<script setup>
/*eslint-disable*/
import { getBodyTemperatureRecordListByClass, editBodyTemperatureRecordRecord, editAllBodyTemperatureRecord } from '@/api/api'
import { useStore } from "vuex";
import { ref, computed, watch, provide } from "vue";
import { useRouter } from "vue-router";
import conversationView from "@/components/conversationView.vue"
import dateSelect from '@/components/dateSelect.vue'
let iseditall = false;
let data = [];
const router = useRouter()
const store = useStore()

const roleID = computed(() => {
    return store.state.roleID
})
const classId = computed(() => {
    return localStorage.getItem('classId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})
const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};
const write = (item) => {
    console.log(list.value)

    iseditall = true;
    if (item === 'all') {
        modifyData2.value.temperatureState = 0;

        modifyData2.value.bodyTemperatureTime = getCurrentTime();
        modifyData2.value.state = 1;
        modifyData2.value.temperature = 0;
    }


    modalStatus2.value = true
}
const stateOptions = ref([
    { value: 0, label: '請選擇' },
    { value: 1, label: '額溫' },
    { value: 2, label: '腋溫' },
    { value: 3, label: '口溫' },
    { value: 4, label: '肛溫' },
    { value: 5, label: '耳溫' }
])
const stateOptions_school = ref([
    { value: 0, label: '入校前測量' },
    { value: 1, label: '午睡後測量' },

])
const sendAll = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    let contactBookRecordIds = list.value.map(item => ({
        contactBookRecordId: item.contactBookRecordId,
        bodyTemperatureTime: modifyData2.value.bodyTemperatureTime,
        state: modifyData2.value.state,
        temperatureState: modifyData2.value.temperatureState,

        temperature: modifyData2.value.temperature
    }));
    formData.append("studentStateRecordList", JSON.stringify(contactBookRecordIds));
    await editAllBodyTemperatureRecord(formData).then((res) => {
        if (res.data.status) {
            cancel()
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
const list = ref([
    {
        isFever: false,
        bodyTemperatureTime: '00:00:00',
    }
])

const feverCount = computed(() => {
    let count = 0
    for (let key in list.value) {
        if (list.value[key].isFever || list.value[key].afterSleepIsFever) {
            count++
        }
    }
    return count
})

const apiLoading = ref(false)

const getList = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())

    await getBodyTemperatureRecordListByClass(formData).then((res) => {
        if (res.data.status) {
            list.value = res.data.data
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const init = async () => {
    apiLoading.value = true
    await getList()
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

const modifyData = ref({
})
const modifyData2 = ref({

    contactBookRecordId: '',
    bodyTemperatureTime: '',
    state: '',
    temperatureState: '',

    temperature: ''
})
const modalStatus = ref(false)
const modalStatus2 = ref(false)

const detail = (item) => {
    modifyData.value = JSON.parse(JSON.stringify(item))
    data = modifyData;
    modalStatus.value = true

}

const cancel = () => {
    modalStatus.value = false
    modalStatus2.value = false
    iseditall = false;
}
provide('cancel', cancel)

const isMobile = computed(() => {
    return store.state.isMobile
})

const edit = async () => {
    var formData = new FormData();
    console.log(modifyData)
    for (var key in modifyData.value) {
        if (modifyData.value.hasOwnProperty(key)) {
            formData.append(key, modifyData.value[key])
        }
    }

    await editBodyTemperatureRecordRecord(formData).then((res) => {
        if (res.data.status) {
            modalStatus.value = false
            getList()
            alert("編輯成功")
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
</script>

<style></style>