<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center">
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div class="px-2">To:</div>
            <div class="px-2">{{ className }}班</div>
        </div>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div class="px-2">共{{ studentList.length }}人</div>
        </div>
        <div v-for="(item, index) in studentList" :key="index" :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
            class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full" @click="upLoadPhoto(index)">
                <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl" class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full" alt="">
            </div>
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center" @click="toShow(index)"
                @dblclick="item.isShow = false">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">學生姓名：{{ item.studentUserName }}</div>
            </div>
            <template v-if="item.isShow">
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">座號：{{ item.number }}</div>
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">生日：{{ formatDate(item.birthday) }}</div>
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">年齡：{{ calculateAge(item.birthday) + '歲' }}
                    </div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">身高：</div>
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <el-input-number v-model="item.height" class="w-full" :precision="2" />
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">體重：</div>
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <el-input-number v-model="item.weight" class="w-full" :precision="2" />
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">BMI：</div>
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <el-input-number v-model="item.bmi" class="w-full" :precision="2" />
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">頭圍：</div>
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <el-input-number v-model="item.headCircumference" class="w-full" :precision="2" />
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">生日：</div>
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <el-input type="date" v-model="item.editedBirthday" class="w-full" :precision="2" />
                </div>
                <div class="w-auto px-1 h-full flex flex-col items-start justify-center" style="max-width: 120;">
                    <img :src="'/api/QRCode/Student?studentId=' + item.studentId" alt="">
                </div>
                <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                    <button @click="edit(item)"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                        更新
                    </button>
                </div>
            </template>
        </div>
        <input ref="filePhotoEle" v-show="false" type="file" capture="environment" @change="handleFiles($event)"
        accept="image/*" />
    </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import { ref, computed } from 'vue'
import { useRouter } from "vue-router"
import { getClassStudentByClassId, editStudent,uploadFile } from '@/api/api'

const store = useStore()
const router = useRouter()

const isMobile = computed(() => {
    return store.state.isMobile
})

const studentList = ref([])

const showIndex = ref(-1)
const photoIndex = ref(-1)

const classId = computed(() => {
    return localStorage.getItem('classId')
})

function calculateAge(birthdayString) {
    const birthday = new Date(birthdayString);
    const today = new Date();

    let age = today.getFullYear() - birthday.getFullYear();
    const monthDiff = today.getMonth() - birthday.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
        age--;
    }

    return age;
}

const className = computed(() => {
    return localStorage.getItem('className')
})

const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date.getFullYear() === 1911 && date.getMonth() === 0 && date.getDate() === 1) {
        return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
};

const getList = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    await getClassStudentByClassId(formData).then((res) => {
        if (res.data.status) {
            studentList.value = res.data.data
            for (let i = 0; i < studentList.value.length; i++) {
                studentList.value[i].isShow = false
                studentList.value[i].editedBirthday = studentList.value[i].birthday.split('T')[0];
            }
            console.log(studentList.value)
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
getList()

const toShow = (index) => {
    showIndex.value = index
    for (let i = 0; i < studentList.value.length; i++) {
        studentList.value[i].isShow = false
        if (i == index) {
            studentList.value[i].isShow = true
        }
    }
}

const edit = async (item) => {
    const formData = new FormData()
    var birthday = new Date(item.editedBirthday)
    let files = ''
    for (let key in fileList.value) {
        files += fileList.value[key]
    }
    formData.append("id", item.studentId)
    formData.append("userId", '')
    formData.append("height", item.height)
    formData.append("weight", item.weight)
    formData.append("bmi", item.bmi)
    formData.append("headCircumference", item.headCircumference)
    formData.append("birthday", birthday.toDateString())
    formData.append("pictureUrl", item.studentUserPictureUrl)
    let res = await editStudent(formData)

    if (res.data.status) {
        alert(res.data.message)
        await getList()
        studentList.value[showIndex.value].isShow = true
    } else {
        alert(res.data.message)
        console.log(res.data.message)
    }
}
const fileList = ref([])

const filePhotoEle = ref(null)
const upLoadPhoto = (index) => {
    filePhotoEle.value.click()
    photoIndex.value = index
}

let islimit = ref(false)
const handleFiles = async (event) => {
    fileList.value = []
    let target = event.target.files

    for (let key in target) {
        if (!isNaN(parseInt(key))) {

            let formData = new FormData();
            formData.append("file", target[key]);
            formData.append("type", "user");

            await uploadFile(formData).then((res) => {
                if (res.data.status) {
                    event.target.files = null
                    studentList.value[photoIndex.value].studentUserPictureUrl = res.data.data
                    alert("上傳成功!!");
                } else {
                    alert(res.data.message);
                }
            }).catch((err) => {
                alert('Error uploading file:', err);
            });
        }
    }
}


</script>

<style></style>