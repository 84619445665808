<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>To:</div>
            <div class="px-2">{{ className }}班</div>
            <div>{{ studentName }} {{ studentNumber }}號</div>
        </div>
        <template v-if="!apiLoading">
            <div
                class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg my-1 px-1 py-2 
                    shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] items-center justify-end">
                <div class="w-full my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold pl-3">
                    當日通知
                </div>
                <div
                    class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start">
                    <div class="relative w-full h-full text-sm md:text-lg">
                        <template v-for="(item, index) in TransmitData" :key="index">
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-black flex flex-wrap items-center justify-start">
                                <div class="px-[1px] flex flex-wrap items-center justify-center">
                                    {{ item.value + ': ' + item.itemValue }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div
                        class="w-full px-2 md:px-4 text-sm md:text-lg text-gray-500 flex flex-wrap items-center justify-start">
                        <input class="w-4 h-4 md:w-5 md:h-5 mr-2" type="checkbox"
                            v-model="contactBookRecordData1.isReadNotice" :disabled="isLocked.isReadNotice" />
                        <label>上述內容是否閱讀完畢(已讀)</label>
                    </div>
                    <button v-if="isParentToTeacher" @click="UploadReceipt"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                        上傳收據
                    </button>
                    <input type="file" id="ReceiptInput" @change="Receiptupload" accept="image/*" style="display: none">
                    <button @click="read(1)"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                        確認
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { getContactBookRecordByClassIdAndStudentId, editTransmitStateRecord, editContactBookRecordByRead2, getTransmitByClassIdAndStudentIdAndDate } from '@/api/api'
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import dateSelect from '@/components/dateSelect.vue'
/*eslint-disable*/
const router = useRouter()
const store = useStore()
const apiLoading = ref(false)
const classId = computed(() => {
    return localStorage.getItem('classId')
})

const studentId = computed(() => {
    return localStorage.getItem('studentId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const studentName = computed(() => {
    return localStorage.getItem('studentName')
})

const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})
const isSchool = computed(() => {
    return (user.value.roleType == 3) || (user.value.roleType == 2)
})

const contactBookRecordData = ref([{}])
const studentStateRecordData = ref([{}])
const isLocked = ref({
    isReadNotice: false,
    isReadStudentState: false
})

const contactBookRecordData1 = ref([{}])
const getContactBookRecord = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("studentId", studentId.value)
    formData.append("date", dayData.value.toDateString())
    await getContactBookRecordByClassIdAndStudentId(formData).then((res) => {
        if (res.data.status) {
            contactBookRecordData1.value = res.data.data
            isLocked.value.isReadNotice = contactBookRecordData1.value.isReadNotice
            isLocked.value.isReadStudentState = contactBookRecordData1.value.isReadStudentState
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const read = async (type) => {
    const formData = new FormData()
    formData.append("id", contactBookRecordData1.value.id)
    formData.append("isReadNotice", contactBookRecordData1.value.isReadNotice)
    formData.append("isReadStudentState", contactBookRecordData1.value.isReadStudentState)
    await editContactBookRecordByRead2(formData).then((res) => {
        if (res.data.status) {
            getContactBookRecord()
            alert(res.data.message)
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}

const TransmitData = ref({})
const transmitStateRecordList = ref({})
let isParentToTeacher = ref(false)
const getTransmit = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("studentId", studentId.value)
    formData.append("date", dayData.value.toDateString())
    await getTransmitByClassIdAndStudentIdAndDate(formData).then((res) => {
        if (res.data.status) {
            transmitStateRecordList.value = res.data.data
            let transmitStateRecordList1 = res.data.data
            let transmitStateGroupList = []

            for (let index in transmitStateRecordList1) {
                let isHave = false
                for (let groupIndex in transmitStateGroupList) {
                    if (transmitStateGroupList[groupIndex].id == transmitStateRecordList1[index].transmitStateGroupId) {
                        isHave = true
                        break;
                    }
                }
                if (!isHave) {
                    let transmitStateGroup = {
                        id: transmitStateRecordList1[index].transmitStateGroupId,
                        key: transmitStateRecordList1[index].transmitStateGroupKey,
                        value: transmitStateRecordList1[index].transmitStateGroupValue
                    }
                    transmitStateGroupList.push(transmitStateGroup)
                }
            }
            transmitStateRecordList1.sort((a, b) => {
                if (a.key < b.key) {
                    return -1;
                }
                if (a.key > b.key) {
                    return 1;
                }
                return 0;
            });
            let transmitStateGroupList2 = [];
            for (let groupIndex in transmitStateGroupList) {
                let itemValue = '';
                for (let index in transmitStateRecordList1) {
                    if (transmitStateGroupList[groupIndex].id == transmitStateRecordList1[index].transmitStateGroupId) {
                        if (transmitStateRecordList1[index].isCheck && transmitStateRecordList1[index].transmitStateItemType == 0) {
                            itemValue += transmitStateRecordList1[index].transmitStateItemValue + '、';
                        }
                        else if (transmitStateRecordList1[index].isCheck && transmitStateRecordList1[index].transmitStateItemType == 1) {
                            itemValue += transmitStateRecordList1[index].transmitStateItemValue + '、';
                        }
                        else if (transmitStateRecordList1[index].isCheck && transmitStateRecordList1[index].transmitStateItemType == 2) {
                            itemValue += transmitStateRecordList1[index].transmitStateItemValue + '、';
                        }
                        else if (transmitStateRecordList1[index].isCheck && transmitStateRecordList1[index].transmitStateItemType == 3) {
                            itemValue += transmitStateRecordList1[index].transmitStateItemValue + '、';
                        }
                    }
                }

                if (itemValue.endsWith('、')) {
                    itemValue = itemValue.slice(0, -1);
                }

                let transmitStateGroup = {
                    id: transmitStateGroupList[groupIndex].id,
                    key: transmitStateGroupList[groupIndex].key,
                    value: transmitStateGroupList[groupIndex].value,
                    itemValue: itemValue
                };

                transmitStateGroupList2.push(transmitStateGroup);
            }
            transmitStateGroupList2.sort((a, b) => {
                if (a.key < b.key) {
                    return -1;
                }
                if (a.key > b.key) {
                    return 1;
                }
                return 0;
            });
            TransmitData.value = transmitStateGroupList2;
            transmitStateRecordList1.forEach(item => {
                if ((item.transmitStateItemKey == "007001" && item.transmitStateGroupKey == "003") || item.transmitStateItemType == 3) {
                    isParentToTeacher = item.isParentToTeacher
                }
            });

        } else {
        }
    }).catch((err) => { })
}

const UploadReceipt = async () => {
    ReceiptInput.click();
}

const Receiptupload = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    let transmitStateRecordList1 = transmitStateRecordList.value
    formData.append("file", file)
    formData.append("type", "transmit")
    await uploadFile(formData).then((res) => {
        if (res.data.status) {
            const updatedRecords = transmitStateRecordList1
                .filter(item => item.transmitStateItemKey === "007001" && item.transmitStateGroupKey === "003")
                .map(item => ({
                    ...item,
                    value: res.data.data
                }));
            formData = new FormData();
            formData.append("TransmitStateRecordList", JSON.stringify(updatedRecords))

            editTransmitStateRecord(formData).then((res) => {
                if (res.data.status) {
                    alert("上傳成功")
                } else {
                    console.log(res.data.message)
                }
            }).catch((err) => { })
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}

const init = async () => {
    apiLoading.value = true
    await getTransmit()
    if (isSchool) {
        getContactBookRecord()
    }
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

</script>