<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center">
      <div
        class="bg-slate-50 w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end"
      >
        <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
          <button
            @click="toSignInScan"
            class="w-full text-white font-bold rounded bg-blue-500 hover:bg-blue-600 flex items-center justify-center py-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <rect width="18" height="18" x="3" y="3" rx="2"/><path d="M21 8H3"/><path d="M8 21V8"/>
            </svg>
            簽到掃描
          </button>
        </div>
      </div>
      <div
        class="bg-slate-50 w-[120%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end"
      >
        <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
          <button
            @click="toSignOutScan"
            class="w-full text-white font-bold rounded bg-blue-500 hover:bg-blue-600 flex items-center justify-center py-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/><polyline points="16 17 21 12 16 7"/><line x1="21" x2="9" y1="12" y2="12"/>
            </svg>
            簽退掃描
          </button>
        </div>
      </div>
    </div>
  </template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import {ref,computed } from 'vue'
import { useRouter } from "vue-router"

const store = useStore()
const router = useRouter()

const isMobile = computed(() => {
    return store.state.isMobile
})


const toSignInScan = () => {
    router.push({ path: '/signInScan' })
}

const toSignOutScan = () => {
    router.push({ path: '/signOutScan' })
}
</script>

<style>

</style>