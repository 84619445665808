<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-start items-center">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div class="px-2">To:</div>
            <div class="px-2">{{ className }}班</div>
        </div>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <button @click="CreateAll"
                class="w-[auto] text-sm md:text-xl text-[#22c55e] mx-[2px] py-[1px] px-[2px] border-[#22c55e] border-[1px] rounded">
                新增全班通知
            </button>
            <button @click="writeAll"
                class="w-[auto] text-sm md:text-xl text-[#22c55e] mx-[2px] py-[1px] px-[2px] border-[#22c55e] border-[1px] rounded">
                全部填寫
            </button>
            <button @click="removeAll"
                class="w-[auto] text-sm md:text-xl text-[#FF4500] mx-[2px] py-[1px] px-[2px] border-[#FF4500] border-[1px] rounded">
                全部清除
            </button>
        </div>
        <template v-if="!apiLoading">
            <template v-if="!CreateAllStatus && !CreateStatus">
                <div v-for="(item, index) in list" :key="index"
                    :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
                    class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg text-[#808080] rounded-lg px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
                    <div class="w-full flex flex-wrap items-center justify-center">
                        <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full ">
                            <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl"
                                class="rounded-full " alt="">
                        </div>
                        <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                            <div class="w-auto flex flex-wrap items-start justify-center">
                                <div class="px-1">{{ item.studentUserName }}</div>
                                <div class="px-1">{{ item.studentNumber + '號' }}</div>
                            </div>
                        </div>
                        <div class="w-auto flex flex-row items-start justify-center">
                           <!--  <button @click="Create(item)"
                                class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                                新增通知
                            </button> -->
                            <button @click="write(item)"
                                class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                                填寫
                            </button>
                            <button v-if="item.isFill" @click="remove(item)"
                                class="w-[auto] text-sm md:text-xl text-[#FF4500] mx-[2px] py-[1px] px-[2px] border-[#FF4500] border-[1px] rounded">
                                清除
                            </button>
                        </div>
                        <div v-if="item.isFill"
                            class="line-style w-full py-2 flex flex-wrap items-center justify-center">
                        </div>
                        <div v-if="item.isFill"
                            class="w-full px-1 text-xs md:text-lg flex flex-col items-start justify-start">
                            <div v-for="(group, groupIndex) in item.TransmitStateGroupList" :key="groupIndex">
                                {{ group.value + ': ' + group.itemValue }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template v-if="!apiLoading">
            <template v-if="CreateAllStatus">
                <div
                    class="w-[95%] md:w-[40%] my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold flex flex-wrap">
                    全班新增通知
                    <button @click="addGroup"
                        class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                        新增標題
                    </button>
                </div>
                <div v-for="(group, groupIndex) in transmitStateGroups" :key="groupIndex"
                    class="w-[95%] md:w-[40%] my-4 p-4 border-2 border-gray-200 rounded-lg">
                    <div class="flex justify-between items-center mb-2">
                        <div class="text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold">
                            通知標題
                        </div>
                        <div>
                            <button @click="addItem(groupIndex)"
                                class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                                新增內容
                            </button>
                            <button @click="removeGroup(groupIndex)" class="text-red-500 ml-2">刪除標題</button>
                        </div>
                    </div>
                    <div
                        class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                        <div class="relative w-full h-full text-sm md:text-lg">
                            <el-input v-model="group.value" class="w-full" size="large" />
                        </div>
                    </div>
                    <div class="mt-4 p-2 border border-gray-300 rounded">
                        <div v-for="(item, itemIndex) in group.TransmitStateItemList" :key="'item-' + itemIndex"
                            class="mb-2">
                            <div class="flex justify-between items-center">
                                <div class="text-[14px] md:text-xl text-left text-[#6E6EFF] font-semibold">
                                    通知內容
                                </div>
                                <button @click="removeItem(groupIndex, itemIndex)"
                                    class="text-red-500 ml-2">刪除內容</button>
                            </div>
                            <div
                                class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                                <div class="relative w-full h-full text-sm md:text-lg">
                                    <el-input v-model="item.value" class="w-full" size="large" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center">
                                <div class="text-[14px] md:text-xl text-left text-[#6E6EFF] font-semibold">
                                    通知內容類型
                                </div>
                            </div>
                            <div class="text-left mt-2">
                                <el-select v-model="item.type" placeholder="選擇類型">
                                    <el-option label="勾選欄" :value="0"></el-option>
                                    <el-option label="輸入框" :value="1"></el-option>
                                    <el-option label="日期" :value="2"></el-option>
                                    <el-option label="檔案" :value="3"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="w-[95%] md:w-[40%] h-auto min-h-[40px] my-1 md:my-3 flex flex-wrap justify-start items-center">
                    <button @click="CreateAllData"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        儲存
                    </button>
                    <button @click="cancel"
                        class="min-w-[20%] bg-gray-500 hover:bg-gray-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        取消
                    </button>
                </div>
            </template>
            <!-- <template v-if="CreateStatus">
                <div
                    class="w-[95%] md:w-[40%] my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold flex items-center space-x-2">
                    <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full overflow-hidden">
                        <img v-if="inputData.studentUserPictureUrl" :src="inputData.studentUserPictureUrl"
                            class="rounded-full object-cover" alt="">
                    </div>
                    <div class="px-2 flex flex-col justify-center">
                        <div class="flex flex-wrap items-start">
                            <div class="px-1">{{ inputData.studentUserName }}</div>
                            <div class="px-1">{{ inputData.studentNumber + '號' }}</div>
                        </div>
                    </div>
                    <button @click="addGroup"
                        class="text-sm md:text-xl text-[#1E90FF] mx-2 py-1 px-2 border-[#1E90FF] border rounded">
                        新增標題
                    </button>
                </div>
                <div v-for="(group, groupIndex) in transmitStateGroups" :key="groupIndex"
                    class="w-[95%] md:w-[40%] my-4 p-4 border-2 border-gray-200 rounded-lg">
                    <div class="flex justify-between items-center mb-2">
                        <div class="text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold">
                            通知標題
                        </div>
                        <div>
                            <button @click="addItem(groupIndex)"
                                class="w-[auto] text-sm md:text-xl text-[#1E90FF] mx-[2px] py-[1px] px-[2px] border-[#1E90FF] border-[1px] rounded">
                                新增內容
                            </button>
                            <button @click="removeGroup(groupIndex)" class="text-red-500 ml-2">刪除標題</button>
                        </div>
                    </div>
                    <div
                        class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                        <div class="relative w-full h-full text-sm md:text-lg">
                            <el-input v-model="group.value" class="w-full" size="large" />
                        </div>
                    </div>
                    <div class="mt-4 p-2 border border-gray-300 rounded">
                        <div v-for="(item, itemIndex) in group.TransmitStateItemList" :key="'item-' + itemIndex"
                            class="mb-2">
                            <div class="flex justify-between items-center">
                                <div class="text-[14px] md:text-xl text-left text-[#6E6EFF] font-semibold">
                                    通知內容
                                </div>
                                <button @click="removeItem(groupIndex, itemIndex)"
                                    class="text-red-500 ml-2">刪除內容</button>
                            </div>
                            <div
                                class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                                <div class="relative w-full h-full text-sm md:text-lg">
                                    <el-input v-model="item.value" class="w-full" size="large" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center">
                                <div class="text-[14px] md:text-xl text-left text-[#6E6EFF] font-semibold">
                                    通知內容類型
                                </div>
                            </div>
                            <div class="text-left mt-2">
                                <el-select v-model="item.type" placeholder="選擇類型">
                                    <el-option label="勾選欄" :value="0"></el-option>
                                    <el-option label="輸入框" :value="1"></el-option>
                                    <el-option label="日期" :value="2"></el-option>
                                    <el-option label="檔案" :value="3"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="w-[95%] md:w-[40%] h-auto min-h-[40px] my-1 md:my-3 flex flex-wrap justify-start items-center">
                    <button @click="CreateData"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        儲存 
                    </button>
                    <button @click="cancel"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        取消
                    </button>
                </div>
            </template> -->
        </template>

        <Teleport to="body">
            <conversationView type="xlarge" v-if="modalAllStatus">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">

                    </div>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="w-[40px] h-[40px] md:w-[70px] md:h-[70px] bg-indigo-500 rounded-full ">
                        </div>
                        <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                            <div class="text-black">全班當日通知單</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-center">
                        當日通知單
                    </div>
                    <div class="max-h-[300px] md:max-h-[400px] scroll-smooth overflow-auto flex flex-wrap ">
                        <template v-for="(group, groupIndex) in inputAllData.TransmitStateGroupList" :key="groupIndex">
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-start">
                                {{ group.value }}
                            </div>
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                                <template v-for="(item, index) in inputAllData.TransmitStateRecordList" :key="index">
                                    <div class="px-[1px] flex flex-wrap items-center justify-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 0">
                                        <input class="w-[15px] h-[15px] md:w-[20px] md:h-[20px]" type="checkbox"
                                            v-model="item.isCheck" />
                                        &nbsp;
                                        <label>{{ item.transmitStateItemValue }}</label>
                                        &nbsp;
                                        &nbsp;
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 1">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 2">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="date" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 3">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="file" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-center items-center">
                        <button @click="sendAll"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            完成
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-gray-500 hover:bg-[#999999] text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
        <Teleport to="body">
            <conversationView type="xlarge" v-if="modalStatus">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">

                    </div>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="w-[40px] h-[40px] md:w-[70px] md:h-[70px] bg-indigo-500 rounded-full ">
                            <img v-if="inputData.studentUserPictureUrl" :src="inputData.studentUserPictureUrl"
                                class="rounded-full " alt="">
                        </div>
                        <div class="w-auto px-2 grow flex flex-col items-start justify-center">
                            <div class="text-black">{{ inputData.studentUserName }}</div>
                            <div>{{ inputData.className + ' ' + inputData.studentNumber + '號' }}</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-center">
                        當日通知單
                    </div>
                    <div class="max-h-[300px] md:max-h-[400px] scroll-smooth overflow-auto flex flex-wrap ">
                        <template v-for="(group, groupIndex) in inputData.TransmitStateGroupList" :key="groupIndex">
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-start">
                                {{ group.value }}
                            </div>
                            <div
                                class="w-full px-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-start">
                                <template v-for="(item, index) in inputData.TransmitStateRecordList" :key="index">
                                    <div class="px-[1px] flex flex-wrap items-center justify-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 0">
                                        <input class="w-[15px] h-[15px] md:w-[20px] md:h-[20px]" type="checkbox"
                                            v-model="item.isCheck" />
                                        &nbsp;
                                        <label>{{ item.transmitStateItemValue }}</label>
                                        &nbsp;
                                        &nbsp;
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 1">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 2">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="date" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                    <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center"
                                        v-if="item.transmitStateGroupId == group.id && item.transmitStateItemType == 3">
                                        <el-input v-model="item.value" size="large" class="w-full" maxlength="500"
                                            :rows="2" type="flie" :autosize="{ minRows: 3, maxRows: 5 }" />
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-center items-center">
                        <button v-if="!isParentToTeacher" @click="UploadReceipt"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            上傳收據
                        </button>
                        <input type="file" id="ReceiptInput" @change="Receiptupload" accept="image/*"
                            style="display: none">
                        <button @click="edit"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            完成
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-gray-500 hover:bg-[#999999] text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
    </div>
</template>

<script setup>
/*eslint-disable*/
import {
    getContactBookRecordByClassId,
    getTransmitStateRecordByClassId,
    editTransmitStateRecord,
    editAllTransmitStateRecord,
    uploadFile,
    InsertAllTransmitStateRecord,
    InsertTransmitStateRecord
} from '@/api/api'
import { ref, computed, watch, provide } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import conversationView from "@/components/conversationView.vue"
import dateSelect from '@/components/dateSelect.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const isMobile = computed(() => {
    return store.state.isMobile
})

const classId = computed(() => {
    return localStorage.getItem('classId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const apiLoading = ref(false)


const classData = ref([
])

const contactBookRecordData = ref([{}])

const list = ref([])

const inputAllData = ref({
    TransmitStateRecordList: [],
    TransmitStateGroupList: []
})

const inputData = ref({
    className: '',
    classCode: '',
    studentUserName: '',
    studentNumber: 0,
    studentId:'',
    TransmitStateRecordList: [],
    TransmitStateGroupList: [],
    isFill: false,
    isParentToTeacher: false
})

const getList = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())

    await getContactBookRecordByClassId(formData).then((res) => {
        if (res.data.status) {
            contactBookRecordData.value = res.data.data
            getTransmitStateRecordList()
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const getTransmitStateRecordList = async () => {
    let dataList = []
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    await getTransmitStateRecordByClassId(formData).then((res) => {
        if (res.data.status) {
            let TransmitStateRecordList = res.data.data
            inputAllData.value.TransmitStateRecordList = [];
            inputAllData.value.TransmitStateGroupList = [];
            for (let key in contactBookRecordData.value) {
                let contactBookRecord = contactBookRecordData.value[key]
                let subTransmitStateRecordList = []
                let TransmitStateGroupList = []
                for (let index in TransmitStateRecordList) {
                    let TransmitStateRecord = TransmitStateRecordList[index]
                    if (TransmitStateRecord.contactBookRecordId == contactBookRecord.id) {
                        subTransmitStateRecordList.push(TransmitStateRecord)
                    }
                }
                let isFill = false
                for (let index in subTransmitStateRecordList) {
                    if (subTransmitStateRecordList[index].isCheck) {
                        isFill = true
                    }
                    let isHave = false
                    for (let groupIndex in TransmitStateGroupList) {
                        if (TransmitStateGroupList[groupIndex].id == subTransmitStateRecordList[index].transmitStateGroupId) {
                            isHave = true
                        }
                    }
                    if (!isHave) {
                        let TransmitStateGroup = {
                            id: subTransmitStateRecordList[index].transmitStateGroupId,
                            key: subTransmitStateRecordList[index].transmitStateGroupKey,
                            value: subTransmitStateRecordList[index].transmitStateGroupValue,
                            isInit: subTransmitStateRecordList[index].isInit,
                        }
                        TransmitStateGroupList.push(TransmitStateGroup)
                    }
                }

                let TransmitStateGroupList1 = []
                for (let groupIndex in TransmitStateGroupList) {
                    let itemValue = ''
                    for (let index in subTransmitStateRecordList) {
                        if ((TransmitStateGroupList[groupIndex].id == subTransmitStateRecordList[index].transmitStateGroupId) && (subTransmitStateRecordList[index].transmitStateItemType == 0) &&
                            (subTransmitStateRecordList[index].isCheck)) {
                            itemValue = itemValue + subTransmitStateRecordList[index].transmitStateItemValue + '、'
                        } else if ((TransmitStateGroupList[groupIndex].id == subTransmitStateRecordList[index].transmitStateGroupId) && (subTransmitStateRecordList[index].transmitStateItemType == 1)) {
                            itemValue = itemValue + subTransmitStateRecordList[index].value + '、'
                        }
                        else if ((TransmitStateGroupList[groupIndex].id == subTransmitStateRecordList[index].transmitStateGroupId) && (subTransmitStateRecordList[index].transmitStateItemType == 2)) {
                            itemValue = itemValue + subTransmitStateRecordList[index].value + '、'
                        }
                        else if ((TransmitStateGroupList[groupIndex].id == subTransmitStateRecordList[index].transmitStateGroupId) && (subTransmitStateRecordList[index].transmitStateItemType == 3)) {
                            itemValue = itemValue + subTransmitStateRecordList[index].value + '、'
                        }
                    }
                    itemValue = itemValue.substring(0, itemValue.length - 1)
                    let TransmitStateGroup = {
                        id: TransmitStateGroupList[groupIndex].id,
                        key: TransmitStateGroupList[groupIndex].key,
                        value: TransmitStateGroupList[groupIndex].value,
                        isInit: TransmitStateGroupList[groupIndex].isInit,
                        itemValue: itemValue
                    }
                    TransmitStateGroupList1.push(TransmitStateGroup)
                }
                TransmitStateGroupList1.sort((a, b) => {
                    return a.key.localeCompare(b.key);
                });
                let data = {
                    className: contactBookRecord.className,
                    classCode: contactBookRecord.classCode,
                    studentUserName: contactBookRecord.studentUserName,
                    studentId: contactBookRecord.studentId,
                    studentUserPictureUrl: contactBookRecord.studentUserPictureUrl,
                    studentNumber: contactBookRecord.studentNumber,
                    TransmitStateRecordList: subTransmitStateRecordList,
                    TransmitStateGroupList: TransmitStateGroupList1,
                    isFill: isFill
                }
                dataList.push(data)
                if (inputAllData.value.TransmitStateRecordList.length < subTransmitStateRecordList.length) {
                    inputAllData.value.TransmitStateRecordList = subTransmitStateRecordList
                    inputAllData.value.TransmitStateGroupList = TransmitStateGroupList1
                }
            }
            list.value = dataList
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

// watch(inputData.body, (newVal,oldval) => {
//     console.log()
// },{ deep: true,immediate: false });

const init = async () => {
    if (apiLoading.value) return false
    apiLoading.value = true
    getList()
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

const modalAllStatus = ref(false)
const modalStatus = ref(false)
const cancel = () => {
    modalStatus.value = false
    modalAllStatus.value = false
    CreateStatus.value = false
    CreateAllStatus.value = false
    transmitStateGroups.value = [ {
        value: '',
        isInit: false,
        TransmitStateItemList: [
            {
                value: '',
                type: 0,
                isParentToTeacher: false
            }
        ]
    }]
}
provide('cancel', cancel)

let isParentToTeacher = ref(false)
const write = (item) => {
    inputData.value = JSON.parse(JSON.stringify(item))
    inputData.value.TransmitStateRecordList.forEach(item => {
        if ((item.transmitStateItemKey == "007001" && item.transmitStateGroupKey == "003") || item.transmitStateItemType == 3) {
            isParentToTeacher = item.isParentToTeacher
        }
    });
    modalStatus.value = true
}

const writeAll = () => {
    for (let index in inputAllData.value.TransmitStateRecordList) {
        inputAllData.value.TransmitStateRecordList[index].contactBookRecordId = '00000000-0000-0000-0000-000000000000'
        inputAllData.value.TransmitStateRecordList[index].isCheck = false
    }

    modalAllStatus.value = true
}

const transmitStateGroups = ref([
    {
        value: '',
        isInit: false,
        TransmitStateItemList: [
            {
                value: '',
                type: 0,
                isParentToTeacher: false
            }
        ]
    }
])

const addGroup = () => {
  transmitStateGroups.value.push({
    value: '',
    isInit: false,
    TransmitStateItemList: [
      {
        value: '',
        type: 0,
        isParentToTeacher: false
      }
    ]
  })
}

const addItem = (groupIndex) => {
  transmitStateGroups.value[groupIndex].TransmitStateItemList.push({
    value: '',
    type: 0,
    isParentToTeacher: false
  })
}

const removeGroup = (index) => {
  transmitStateGroups.value.splice(index, 1)
  if (transmitStateGroups.value.length === 0) {
    addGroup()
  }
}

const removeItem = (groupIndex, itemIndex) => {
  transmitStateGroups.value[groupIndex].TransmitStateItemList.splice(itemIndex, 1)
  if (transmitStateGroups.value[groupIndex].TransmitStateItemList.length === 0) {
    addItem(groupIndex)
  }
}
//個別新增功能
const CreateStatus = ref(false)
/* const Create = async (item) => {
    inputData.value = JSON.parse(JSON.stringify(item))
    CreateStatus.value = true
    CreateAllStatus.value = false
} */

const CreateAllStatus = ref(false)
const CreateAll = async () => {
    CreateStatus.value = false
    CreateAllStatus.value = true
}

const edit = async () => {
    const formData = new FormData()
    inputData.value.TransmitStateRecordList.forEach(item => {
        if ((item.transmitStateItemKey == "007001" && item.transmitStateGroupKey == "003") || item.transmitStateItemType == 3) {
            item.value = files
        }
    });
    formData.append("TransmitStateRecordList", JSON.stringify(inputData.value.TransmitStateRecordList))

    await editTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            cancel()
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}
//個別新增功能
/* const CreateData = async () => {
    const TransmitStateRecordList = transmitStateGroups.value
        .filter(group => group.value.trim() !== '' || group.TransmitStateItemList.length > 0)
        .map(group => ({
            value: group.value,
            isInit: false,
            TransmitStateItemList: group.TransmitStateItemList
                .filter(item => item.value.trim() !== '')
                .map(item => ({
                    value: item.value,
                    type: item.type,
                    isParentToTeacher: item.isParentToTeacher
                }))
        }))
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    formData.append("studentId", inputData.value.studentId)
    formData.append("TransmitStateRecordList", JSON.stringify(TransmitStateRecordList))
    await InsertTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            cancel()
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
} */

const CreateAllData = async () => {
    const TransmitStateRecordList = transmitStateGroups.value
        .filter(group => group.value.trim() !== '' || group.TransmitStateItemList.length > 0)
        .map(group => ({
            value: group.value,
            isInit:false,
            TransmitStateItemList: group.TransmitStateItemList
                .filter(item => item.value.trim() !== '')
                .map(item => ({
                    value: item.value,
                    type: item.type,
                    isParentToTeacher: item.isParentToTeacher
                }))
        }))
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    formData.append("TransmitStateRecordList", JSON.stringify(TransmitStateRecordList))
    await InsertAllTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            cancel()
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const sendAll = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    formData.append("TransmitStateRecordList", JSON.stringify(inputAllData.value.TransmitStateRecordList))

    await editAllTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            cancel()
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const remove = async (item) => {
    inputData.value = JSON.parse(JSON.stringify(item))
    for (let index in inputData.value.TransmitStateRecordList) {
        inputData.value.TransmitStateRecordList[index].isCheck = false
    }
    const formData = new FormData()
    formData.append("TransmitStateRecordList", JSON.stringify(inputData.value.TransmitStateRecordList))

    await editTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const removeAll = async () => {
    for (let index in inputAllData.value.TransmitStateRecordList) {
        inputAllData.value.TransmitStateRecordList[index].isCheck = false
    }
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    formData.append("TransmitStateRecordList", JSON.stringify(inputAllData.value.TransmitStateRecordList))

    await editAllTransmitStateRecord(formData).then((res) => {
        if (res.data.status) {
            apiLoading.value = true
            getList()
            apiLoading.value = false
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const checkInput = (obj, index) => {
    if (obj.status[index]) {
        return false
    }

    for (let key in obj.status) {
        if (key == index.toString()) {
            obj.status[key] = true
        } else {
            obj.status[key] = false
        }
    }
}
const UploadReceipt = async () => {
    ReceiptInput.click();
}
let files = ref(null)
const Receiptupload = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file)
    formData.append("type", "transmit")
    await uploadFile(formData).then((res) => {
        if (res.data.status) {
            files = res.data.data
            alert("上傳成功")
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}


</script>