<template>
  <div class="container mx-auto">
    <div class="container mx-auto px-4 md:px-28">
        <div class="mt-3">
            <div class="md:flex flex-wrap">
                <div class="grid grid-cols-1 lg:grid-cols-4 gap-0 lg:gap-2 grid-row-span-5">
                    <div class="md:col-span-3 relative">
                        <!-- <a href="#">
                            <img src="@/assets/img/00efb1e1d443521316966bc7e141fb41-38869.jpg" class="h-full" alt="">
                        </a> -->
                        <swiper class="w-auto h-[100%]" :loop="true" :modules="modules"
                            :pagination="{ clickable: true }" :slides-per-view="1" :space-between="10" :scrollbar="{ draggable: true }">
                            <swiper-slide>
                              <div class="w-full h-[100%]">
                                  <img src="@/assets/img/00efb1e1d443521316966bc7e141fb41-38869.jpg" class="w-[100%] h-[100%]" >
                              </div>
                            </swiper-slide>
                            <swiper-slide>
                              <div class="w-full h-[100%]">
                                  <img src="@/assets/img/7ab9ccc609e1d8d035c15df2c8301d5d-133722.jpg" class="w-[100%] h-[100%]" >
                              </div>
                            </swiper-slide>
                            <swiper-slide>
                              <div class="w-full h-[100%]">
                                  <img src="@/assets/img/article-62598c9e71079.jpeg" class="w-[100%] h-[100%]" >
                              </div>
                            </swiper-slide>
                        </swiper>
                        <!-- <swiper-container class="mySwiper" init="false" style="height: 100%; width: auto;">
                            <swiper-slide><img src="@/assets/img/00efb1e1d443521316966bc7e141fb41-38869.jpg" alt="" class="h-full" ></swiper-slide>
                            <swiper-slide><img src="@/assets/img/6d07c00c1b110d1f027084faade5a7ae-560x317.jpg" alt="" width="100%" class="h-full"></swiper-slide>
                            <swiper-slide><img src="@/assets/img/7ab9ccc609e1d8d035c15df2c8301d5d-133722.jpg" alt="" width="100%" class="h-full"></swiper-slide>
                            <swiper-slide><img src="@/assets/img/article-62598c9e71079.jpeg" alt="" width="100%" class="h-full"></swiper-slide>
                        </swiper-container> -->
                    </div>
                    <div class="bg-success border-l border-solid border-white lg:col-span-1">
                        <ul class="grid lg:grid-cols-1 gap-0">
                          <li class="py-9 px-3.5 h-auto lg:h-36 border-b border-solid border-white text-white font-bold text-lg flex items-center justify-between">
                            <span class="flex-grow text-xl md:text-2xl">直覺的操作介面</span>
                            <img src="@/assets/img/Polygon 24.png" class="w-2 h-3 md:w-4 md:h-5">
                          </li>
                          <li class="py-9 px-3.5 h-auto lg:h-36 border-b border-solid border-white text-white font-bold text-lg flex items-center justify-between">
                            <span class="flex-grow text-xl md:text-2xl">專業形象網站</span>
                            <img src="@/assets/img/Polygon 24.png" class="w-2 h-3 md:w-4 md:h-5">
                          </li>
                          <li class="py-9 px-3.5 h-auto lg:h-36 border-b border-solid border-white text-white font-bold text-lg flex items-center justify-between">
                            <span class="flex-grow text-xl md:text-2xl">功能齊全ALL IN ONE</span>
                            <img src="@/assets/img/Polygon 24.png" class="w-2 h-3 md:w-4 md:h-5">
                          </li>
                          <li class="py-9 px-3.5 h-auto lg:h-36 border-b border-solid border-white text-white font-bold text-lg flex items-center justify-between">
                            <span class="flex-grow text-xl md:text-2xl">完整財務系統管理</span>
                            <img src="@/assets/img/Polygon 24.png" class="w-2 h-3 md:w-4 md:h-5">
                          </li>
                        </ul>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mx-auto px-4 md:px-28 mt-3">
            <div class="grid grid-cols-1 lg:grid-cols-8 gap-0 lg:gap-2">
                <div class="lg:col-span-2">
                    <ul class="mb-3">
                        <li class="p-3 border-solid font-bold text-lg text-center text-white bg-success">智光系統項目</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                    </ul>
                    <ul class="mb-3">
                        <li class="p-3 border-b border-solid font-bold text-lg text-center text-white bg-success">進存服務項目</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                    </ul>
                    <ul class="mb-3">
                        <li class="p-3 border-b border-solid font-bold text-lg text-center text-white bg-success">智光i學園</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words">-</li>
                    </ul>
                </div>
                <div class="md:col-span-4">
                    <h3 class="border-b-4 text-lg border-solid border-black text-3xl font-bold mb-3 h-10">最新消息</h3>
                    <div class="mb-3">
                        <a href=""><img src="@/assets/img/7ab9ccc609e1d8d035c15df2c8301d5d-1337222.jpg" class="w-full h-full object-cover" alt=""></a>
                    </div>
                    <ul class="mb-3">
                        <li class="py-3 grid grid-cols-1 md:grid-cols-4 gap-2 flex items-center">
                            <div class="col-span-2 border border-solid border-dark">
                                <img src="@/assets/img/F1463042289467.jpg" class="w-full h-full object-cover" alt="">
                            </div>
                            <h5 class="col-span-2 font-bold text-lg break-words">
                                單位簡介 : 訊息e點通APP功能特色產品特色
                            </h5>
                        </li>
                        <li class="py-3 grid grid-cols-1 md:grid-cols-4 gap-2 flex items-center">
                            <div class="col-span-2 border border-solid border-dark">
                                <img src="@/assets/img/math-education-huang-min-hsiung-20170728-02.jpg" class="w-full h-full object-cover" alt="">
                            </div>
                            <h5 class="col-span-2 font-bold text-lg break-words">
                                智光已進駐台中軟體園區，歡迎舊雨新知，蒞臨指導
                            </h5>
                        </li>
                        <li class="py-3 grid grid-cols-1 md:grid-cols-4 gap-2 flex items-center">
                            <div class="col-span-2 border border-solid border-dark">
                                <img src="@/assets/img/article-62598c9e71079.jpeg" class="w-full h-full object-cover" alt="">
                            </div>
                            <h5 class="col-span-2 font-bold text-lg break-words">
                                速算家雲端版，提供不同平台，多加公司，多部門的協同性操作體驗
                            </h5>
                        </li>
                    </ul>
                    <div class="border-t-4 border-black border-top mt-3 py-5">
                        <button class="px-2 py-2 font-semibold text-lg bg-sky-500/75 text-white shadow-sm opacity-100 w-40 flex justify-between items-center">
                            <h4 class="fw-bold text-white">查看全部</h4>
                            <img src="@/assets/img/Polygon 24.png" class="float-right w-2 h-3 md:w-4 md:h-5">
                        </button>
                    </div>
                </div>
                <div class="md:col-span-2">
                    <ul class="mb-3">
                        <li class="p-3 border-solid font-bold text-lg flex justify-between text-white bg-success">
                            <h4>智光快訊</h4>
                            <div class="ms-auto">
                                <a href=""><h6>查看全部</h6></a>
                            </div>
                        </li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                    </ul>
                    <ul class="mb-3">
                        <li class="p-3 border-solid font-bold text-lg flex justify-between text-white bg-success">
                            <h4>智光i學園-改版資訊</h4>
                            <div class="ms-auto">
                                <a href=""><h6>查看全部</h6></a>
                            </div>
                        </li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                        <li class="p-3 border-b border-solid border-dark break-words overflow:hidden;">-</li>
                    </ul>
                </div>
            </div>
    </div>

  </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import {ref,computed } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useRouter, useRoute } from "vue-router";
import { getLineLoginCallback, authorize } from '@/api/api'
import 'swiper/css';
import 'swiper/css/navigation';
import tailView from "@/components/tailView.vue"
import bookView from "@/components/bookView.vue"

const store = useStore()
const router = useRouter()
const route = useRoute()
const bannerData = ref([])
const newsData = ref([])

const isMobile = computed(() => {
    return store.state.isMobile
})

const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const code = getParameterByName('code');
const state = getParameterByName('state');

const checkLineLogin = () =>  {
    let payload = {
        code: '',
        state: ''
    }
    if(route.query.code && route.query.state){
      payload = {
        code: route.query.code,
        state: route.query.state
      }
    } else if(window.location.href != ''){
      payload = {
        code: getParameterByName('code',window.location.href),
        state: getParameterByName('state',window.location.href)
      }
    }
    if(payload.code && payload.state){
      getLineLoginCallback(payload).then((res) => {
        // console.log('getLineLoginCallback',res)
        if(res.data.status){
          if (res.data.data.state == 0) {
            store.commit('setToken',res.data.data.data)
            authorize().then((res1) => {
                if(res1.data.status){
                    store.commit('setUser',res1.data.data)
                    // window.location.replace((window.location.origin + window.location.pathname))
                    router.push('/home');
                }else{
                    console.log(res1.data.message)
                }
            }).catch((err) => { })
          }else if (res.data.data.state == 1) {
            store.commit('setLineId',res.data.data.data)
            router.push('/profile') 
          }else if (res.data.data.state == 2) {
            store.commit('setToken',res.data.data.data)
            authorize().then((res1) => {
                if(res1.data.status){
                    store.commit('setStudentIdByLine',res.data.data.data)
                    store.commit('setUser',res1.data.data)
                    router.push('/profile')
                }else{
                    console.log(res1.data.message)
                }
            }).catch((err) => { })
          }
        }else{
          console.log(res.data.message)
        }
      }).catch((err) => { })
    }
  }

const init = async() => {
    authorize().then((res) => {
        if(res.data.status){
            store.commit('setUser',res.data.data)
        }else{
            checkLineLogin()
        }
    }).catch((err) => {
        checkLineLogin()
    })
    bannerData.value = [
      {ImageUrl:require('@/assets/img/00efb1e1d443521316966bc7e141fb41-38869.jpg')},
      {ImageUrl:require('@/assets/img/carousel-1.jpg')}
    ]
    for(let item of bannerData.value){
      const img = new Image();
      img.src = item.ImageUrl;
      img.onload = function() {
        item.width = img.width
        item.height = img.height
      }
    }
    // //最新消息
    // let newsSearchPayload = {
    //   "DepartmentID":"2bd3434f-1e95-4fdb-ab29-315921e06868",
    //   "page":0,
    //   "rowsInPage":2,
    //   "HideExpired":false
    // }
    // await getNewsSearch(newsSearchPayload).then((res) => {
    //   newsData.value = res.data.Result.NewsList
    //   // console.log('newsData.value',newsData.value)
    // })
    // .catch((error) => {
    //   // handle error
    //   console.log(error);
    // })
}

init()

let swiperItem = null
const onSwiper = (element) => {
  // console.log('element',element)
  swiperItem = element;
};

const onSlideChange = () => {
  // console.log('slide change');
};

const countHeight = (w,h,width) => {
  //pc width 50vw phone width 100vw
  return (Math.round(h*width/w))+'vw'
  // return isMobile.value ? (Math.round(h*100/w))+'vw' : (Math.round(h*100/w)/2)+'vw'
}

// const toIntroduction = () => {
//   router.push({ path: 'introduction' })
// }

// const toNews = (item) => {
//   if(item){
//     router.push({ 
//       name: 'news',
//       query:{
//         NewsID:item.NewsID
//       }
//     })
//   }else{
//     router.push({ path: 'news' })
//   }
// }

</script>

<style lang="scss" scoped>
.float-right {
  max-width: 24px;
}
/*
.run {
  position: relative;
  animation: Marquee 6s linear 2s infinite;
}

@keyframes Marquee {
  0%   {transform: translateX(0vw);}
  25%  {transform: translateX(25vw);}
  50%  {transform: translateX(50vw);}
  75%  {transform: translateX(75vw);}
  100% {transform: translateX(100vw);}
}
*/
</style>
