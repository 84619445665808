<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>To:</div>
            <div class="px-2">{{ className }}班</div>
        </div>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <button v-if="isSchool" @click="write('all')"
                class="w-[auto] text-sm md:text-xl text-[#22c55e] mx-[2px] py-[1px] px-[2px] border-[#22c55e] border-[1px] rounded">
                新增
            </button>
        </div>

        <template v-if="!apiLoading">
            <template v-if="!isEdit">
                <div class="w-[95%] md:w-[40%] my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold">活動名稱
                </div>
                <div class="w-[90%] md:w-[40%] h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] 
                    flex flex-wrap items-start justify-center">
                    <div class="relative w-full h-full text-sm md:text-lg">
                        <el-input v-model="modifyData.title" class="w-full" size="large" :disabled="!isSchool" />
                    </div>
                </div>
                <div class="w-[95%] md:w-[40%] my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold">活動紀錄
                </div>
                <div class="w-[90%] md:w-[40%] h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                    flex flex-wrap items-start justify-center">
                    <div class="relative w-full h-full text-sm md:text-lg">
                        <el-input v-model="modifyData.remark" size="large" class="w-full" maxlength="500" :rows="2"
                            type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" :disabled="!isSchool" />
                    </div>
                </div>
                <div v-if="isSchool"
                    class="w-[95%] md:w-[40%] h-auto min-h-[40px] my-1 md:my-3 flex flex-wrap justify-start items-center">
                    <button @click="upLoadPhone" :disabled="islimit"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        上傳照片
                    </button>
                    <button @click="setInsertData"
                        class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                        儲存
                    </button>
                </div>
            </template>
        </template>

        <template v-if="!apiLoading">
            <template v-if="list && list.length > 0">
                <div v-for="(item, index) in list" :key="index" @click="detail(item)" @dblclick="selectedItem = null"
                    :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
                    class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg my-1 px-1 py-2 
                    shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] items-center justify-end">
                    <div class="flex flex-wrap ">
                        <div class="w-auto px-2 h-full grow flex flex-col items-start justify-center">
                            <div class="w-auto flex flex-wrap items-start justify-center">
                                <div class="px-1">{{ item.title }}</div>
                            </div>
                            <div class="w-auto flex flex-wrap items-start justify-start">
                                <div class="px-1">{{ item.remark }}</div>
                            </div>
                        </div>
                        <div class="w-auto px-2 h-full grow flex flex-col items-end justify-center">
                            <div class="w-auto flex flex-wrap items-start justify-center">
                                <div class="px-1"></div>
                            </div>
                            <div class="w-auto flex flex-wrap items-center justify-center">
                                <div class="px-1">{{ formatDate(item.createDateTime) }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedItem === item.id && modalStatus && isEdit">
                        <div class="w-full my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold pl-3">
                            活動名稱
                        </div>
                        <div
                            class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                            <div class="relative w-full h-full text-sm md:text-lg">
                                <el-input v-model="modifyData.title" class="w-full" size="large" />
                            </div>
                        </div>
                        <div
                            class="w-[95%] md:w-[40%] my-2 text-[16px] md:text-2xl text-left text-[#6E6EFF] font-semibold pl-3">
                            活動紀錄
                        </div>
                        <div
                            class="w-full h-auto rounded-lg bg-slate-50 m-1 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-start justify-center">
                            <div class="relative w-full h-full text-sm md:text-lg">
                                <el-input v-model="modifyData.remark" size="large" class="w-full" maxlength="500"
                                    :rows="2" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
                                    :disabled="!isSchool" />
                            </div>
                        </div>
                        <div v-if="isSchool"
                            class="w-[95%] md:w-[40%] h-auto min-h-[40px] my-1 md:my-3 flex flex-wrap justify-start items-center">
                            <button @click="upLoadPhone" :disabled="islimit"
                                class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                                上傳照片
                            </button>
                            <button @click="setEditData(item)"
                                class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 rounded">
                                儲存
                            </button>
                        </div>
                        <div class="w-full h-auto min-h-[40px] my-1 md:my-3 flex flex-wrap justify-start items-center">
                            <swiper class="relative w-[95%] h-auto" :loop="true" :modules="modules"
                                :pagination="{ clickable: true }" :slides-per-view="1" :space-between="10"
                                @swiper="onSwiper" @slideChange="onSlideChange" :scrollbar="{ draggable: true }">
                                <swiper-slide class="relative w-full flex justify-center" v-for="(item, index) in album"
                                    :key="index">
                                    <div class="w-full h-[100%] pb-[50%]">
                                        <img :src="item.ImageUrl" class="w-[95%] h-[100%]">
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg my-1 px-1 py-2 
                shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] items-center justify-end">
                今日無活動紀錄
                </div>
            </template>
        </template>
        <input ref="fileEle" v-show="false" type="file" id="file-uploader" data-target="file-uploader"
            accept="image/*,.pdf,.docx,.doc" @change="handleFiles($event)" multiple="multiple" />
        <input ref="filePhoneEle" v-show="false" type="file" capture="environment" @change="handleFiles($event)"
            accept="image/*" />
    </div>
</template>

<script setup>
/*eslint-disable*/
import { uploadFile, editAnnouncement, getAnnouncementByClassIdAndDate, createAnnouncement } from '@/api/api'
import { useStore } from "vuex";
import { ref, computed } from 'vue'
import { useRouter } from "vue-router";
import signalR from '@/utils/signalR'
import dateSelect from '@/components/dateSelect.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
const router = useRouter()
const store = useStore()
const isMobile = computed(() => {
    return store.state.isMobile
})
const roleID = computed(() => {
    return store.state.roleID
})

const user = computed(() => {
    return store.state.user
})

const isSchool = computed(() => {
    return (user.value.roleType == 3) || (user.value.roleType == 2)
})

const classId = computed(() => {
    return localStorage.getItem('classId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const apiLoading = ref(false)

const modifyData = ref({
    remark: ''
})

const fileList = ref([])

const fileEle = ref(null)
const upLoad = () => {
    fileEle.value.click()
}

const album = ref([])

const isEdit = ref(true)
const write = (item) => {
    if (item == 'all') {
        isEdit.value = false
        modifyData.value = {
            notification: {
                status: false,
                day: new Date().toISOString().substring(0, 10)
            },
            worksheet: {
                status: false,
                day: new Date().toISOString().substring(0, 10)
            },
            receipt: {
                status: false,
                money: '0'
            },
            things: {
                suit: false,
                shoe: false,
                bedding: false,
                toiletry: false
            }
        }
    } else {
        modifyData.value = item
    }

    modalStatus.value = true
}

let swiperItem = null
const onSwiper = (element) => {
    // console.log('element',element)
    swiperItem = element;
};

const onSlideChange = () => {
    // console.log('slide change');
};

const countHeight = (w, h, width) => {
    //pc width 50vw phone width 100vw
    return (Math.round(h * width / w)) + 'vw'
    // return isMobile.value ? (Math.round(h*100/w))+'vw' : (Math.round(h*100/w)/2)+'vw'
}

const modalStatus = ref(false)
const selectedItem = ref(null)
const detail = (item) => {
    selectedItem.value = item.id;
    modalStatus.value = true;
    modifyData.value = JSON.parse(JSON.stringify(item));
    isEdit.value = true;
    fileList.value = [];
    let files = modifyData.value.files.split('&');
    for (let key in files) {
        if (files[key] != '') {
            fileList.value.push(files[key]);
        }
    }
    album.value = fileList.value.map(file => ({ ImageUrl: file }));
};

const formatDate = (dateTime) => {
    let date = new Date(dateTime);
    let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let formattedDate = date.toLocaleDateString('zh-TW', options).replace(/\//g, '/');
    let formattedTime = date.toLocaleTimeString('en-US', { hour12: false });

    let AM = date.getHours() < 12 ? '上午' : (date.getHours() < 18 ? '下午' : '晚上')
    let hours = date.getHours() <= 12 ? date.getHours() : date.getHours() - 12
    hours = hours < 10 ? '0' + hours : '' + hours
    let mins = date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes()
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : '' + date.getSeconds()

    return (formattedDate + ' ' + AM + ' ' + hours + ':' + mins)
}

const list = ref([
    {
        title: '',
        remark: '',
        createDateTime: ''
    },
])

const getData = async () => {
    fileList.value = []
    let formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("date", dayData.value.toDateString())
    await getAnnouncementByClassIdAndDate(formData).then((res) => {
        if (res.data.status) {
            list.value = res.data.data
            let files = res.data.data.files.split('&')
            for (let key in files) {
                if (files[key] != '') {
                    fileList.value.push(files[key])
                }
            }
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}

const setInsertData = async () => {
    let files = ''
    for (let key in fileList.value) {
        files += fileList.value[key] + '&'
    }
    let formData = new FormData();
    formData.append("classId", classId.value)
    formData.append("title", modifyData.value.title)
    formData.append("remark", modifyData.value.remark)
    formData.append("files", files)
    await createAnnouncement(formData).then((res) => {
        if (res.data.status) {
            let data = 'classId=' + classId.value
            signalR.sendNotify(1, data)
            alert(res.data.message)
            window.location.reload();
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}

const setEditData = async (item) => {
    let files = ''
    for (let key in fileList.value) {
        files += fileList.value[key] + '&'
    }
    let formData = new FormData();
    formData.append("id", item.id)
    formData.append("classId", classId.value)
    formData.append("title", modifyData.value.title)
    formData.append("remark", modifyData.value.remark)
    formData.append("files", files)
    await editAnnouncement(formData).then((res) => {
        if (res.data.status) {
            let data = 'classId=' + classId.value
            signalR.sendNotify(1, data)
            alert(res.data.message)
            window.location.reload();
        } else {
            alert(res.data.message)
        }
    }).catch((err) => { })
}
let islimit = ref(false)
const handleFiles = async (event) => {
    fileList.value = []
    let target = event.target.files

    for (let key in target) {
        if (!isNaN(parseInt(key))) {
            if (fileList.value >= 10) {
                alert("最多上傳10張圖片");
                islimit = true;
                break;
            }

            let formData = new FormData();
            formData.append("file", target[key]);
            formData.append("type", "announcement");

            await uploadFile(formData).then((res) => {
                if (res.data.status) {
                    fileList.value.push(res.data.data);
                    alert("上傳成功!!");
                } else {
                    alert(res.data.message);
                }
            }).catch((err) => {
                alert('Error uploading file:', err);
            });
        }
    }
}

const filePhoneEle = ref(null)
const upLoadPhone = () => {
    filePhoneEle.value.click()
}

const init = async () => {
    // if(apiLoading.value) return false
    await getData()
    apiLoading.value = true
    // await getData()
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

</script>

<style></style>