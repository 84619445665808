<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>To:</div>
            <div class="px-2">{{ className }}班</div>
            <div>{{ studentName }} {{ studentNumber }}號</div>
        </div>
        <template v-if="!apiLoading">
            <div
                class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
                <div class="w-full md:w-1/2 py-1 md:py-3 px-3">
                    <div class="text-[16px] md:text-2xl text-[#6E6EFF] font-semibold mb-2">入校前測量體溫</div>
                    <div class="flex flex-col space-y-4">
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">測量時間</label>
                            <el-time-select v-model="temperatureData.bodyTemperatureTime" :clearable="false"
                                start="00:10" step="00:10" end="23:50" placeholder="Select time" disabled
                                class="w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">測量類型</label>
                            <el-select v-model="temperatureData.state" placeholder="請選擇類型" size="large" disabled
                                class="w-full">
                                <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">入校前體溫</label>
                            <input v-model="temperatureData.temperature" required type="number" disabled
                                class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        </div>
                        <div v-if="temperatureData.isFever"
                            class="w-auto bg-[#DC3545] text-sm md:text-xl text-white py-[1px] px-[2px] rounded flex items-center justify-center">
                            發燒
                        </div>
                        <div v-else
                            class="w-auto bg-[#0D6EFD] text-sm md:text-xl text-white py-[1px] px-[2px] rounded flex items-center justify-center">
                            正常
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-1/2 py-1 md:py-3 px-3">
                    <div class="text-[16px] md:text-2xl text-[#6E6EFF] font-semibold mb-2">午睡後測量體溫</div>
                    <div class="flex flex-col space-y-4">
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">測量時間</label>
                            <el-time-select v-model="temperatureData.afterSleepTime" :clearable="false" start="00:10"
                                step="00:10" end="23:50" placeholder="Select time" disabled class="w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">測量類型</label>
                            <el-select v-model="temperatureData.afterSleepstate" placeholder="請選擇類型" size="large"
                                class="w-full" disabled>
                                <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">體溫</label>
                            <input v-model="temperatureData.afterTemperature" required type="number" disabled
                                class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        </div>
                        <div v-if="temperatureData.afterSleepIsFever"
                            class="w-auto bg-[#DC3545] text-sm md:text-xl text-white py-[1px] px-[2px] rounded flex items-center justify-center">
                            發燒
                        </div>
                        <div v-else
                            class="w-auto bg-[#0D6EFD] text-sm md:text-xl text-white py-[1px] px-[2px] rounded flex items-center justify-center">
                            正常
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { getBodyTemperatureRecordByClassIdAndStudentId } from '@/api/api'
import { useStore } from "vuex";
import { ref, computed} from "vue";
import { useRouter } from "vue-router";
import dateSelect from '@/components/dateSelect.vue'
/*eslint-disable*/
const router = useRouter()
const store = useStore()
const apiLoading = ref(false)
const classId = computed(() => {
    return localStorage.getItem('classId')
})

const studentId = computed(() => {
    return localStorage.getItem('studentId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const studentName = computed(() => {
    return localStorage.getItem('studentName')
})

const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})


const temperatureStateOptions = ref([
    { value: 0, label: '請選擇' },
    { value: 1, label: '額溫' },
    { value: 2, label: '腋溫' },
    { value: 3, label: '口溫' },
    { value: 4, label: '肛溫' },
    { value: 5, label: '耳溫' }
])

const temperatureData = ref({})
const getTemperature = async () => {
    const formData = new FormData()
    formData.append("classId", classId.value)
    formData.append("studentId", studentId.value)
    formData.append("date", dayData.value.toDateString())
    await getBodyTemperatureRecordByClassIdAndStudentId(formData).then((res) => {
        if (res.data.status) {
            temperatureData.value = res.data.data
            dialogStatus.value = true
        } else {
            console.log(res.data.message)
        }
    }).catch((err) => { })
}

const init = async () => {
    apiLoading.value = true
    await getTemperature()
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

</script>