<template>
    <div class="w-auto h-auto md:h-[80%] p-2 flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center">
            <div class="mr-auto flex flex-wrap items-center">
                <div>To:</div>
                <div class="px-2">{{ className }}班</div>
                <div>{{ studentName }} {{ studentNumber }}號</div>
            </div>
            <strong class="ml-auto">總計點數：{{ totalPoints }}</strong>
        </div>
        <template v-if="!apiLoading">
            <div
                class="w-[95%] md:w-[40%] mx-auto text-base md:text-xl rounded-lg bg-slate-50 p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <h2 class="text-xl font-semibold text-gray-800 mb-4">週菜單</h2>
                <div class="space-y-4">
                    <div v-for="day in workDays" :key="day.date" class="border-b border-gray-200 pb-4 last:border-b-0">
                        <div class="flex justify-between items-center mb-2">
                            <span class="font-bold text-lg text-gray-700">{{ day.dayName }}</span>
                            <span class="text-sm text-gray-500">{{ day.date }}</span>
                        </div>
                        <div class="space-y-2">
                            <div v-if="day.selectedFoods.length === 0 || day.selectedFoods.every(food => !food)"
                                class="bg-white p-2 rounded-md shadow-sm text-gray-400 italic">
                                未選擇菜品
                            </div>
                            <template v-else>
                                <div v-for="(food, index) in day.selectedFoods" :key="index"
                                    class="bg-white p-2 rounded-md shadow-sm">
                                    <div v-if="food" class="text-gray-700">
                                        {{ food.foodName }}
                                        <span class="text-sm text-gray-500 ml-2">
                                            ({{ food.point }}點) | {{ GetTypeName(food.type) }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="mt-2">
                            <button @click="Order(day)" :class="[
                                'w-[auto] text-sm md:text-xl mx-[2px] py-[1px] px-[2px] border-[1px] rounded',
                                orderStatus[day.id]
                                    ? 'text-gray-400 border-gray-400 cursor-not-allowed'
                                    : 'text-[#22c55e] border-[#22c55e] hover:bg-[#22c55e] hover:text-white'
                            ]" :disabled="orderStatus[day.id]">
                                {{ orderStatus[day.id] ? '已訂購' : '訂購' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <Teleport to="body">
            <conversationView type="large" v-if="modalStatus">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="mr-auto flex flex-wrap items-center">
                            <div>To:</div>
                            <div class="px-2">{{ className }}班</div>
                            <div>{{ studentName }} {{ studentNumber }}號</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-center">
                        確認訂單
                    </div>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-col items-center justify-center">
                        <p class="mb-2">日期：{{ currentOrder.day.dayName }} ({{ currentOrder.day.date }})</p>
                        <div v-for="food in currentOrder.day.selectedFoods" :key="food.foodName" class="mb-1">
                            <span v-if="food">{{ food.foodName }} ({{ food.point }}點)</span>
                        </div>
                        <p class="mt-4 font-bold">總點數：{{ currentOrder.totalPoint }}</p>
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-center items-center">
                        <button @click="Confirm"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            確認
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-gray-500 hover:bg-[#999999] text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
    </div>
</template>
<script setup>
import {
    getDataListByDate, getDataListByDishId, getFoodDataList,
    getFoodDetail, getDetail, editParentForPoint,
    CreateOrderRecord, getOrderRecordByStudentId
} from '@/api/api'
import { useStore } from "vuex";
import { ref, computed, provide, reactive } from "vue";
import { useRouter } from "vue-router";
import dateSelect from '@/components/dateSelect.vue'
import conversationView from "@/components/conversationView.vue"
/*eslint-disable*/
const router = useRouter()
const store = useStore()
const apiLoading = ref(false)
const totalPoints = ref(0)
const FoodList = ref([])
const orderStatus = reactive({});
const user = computed(() => {
    return store.state.user
})

const classId = computed(() => {
    return localStorage.getItem('classId')
})

const studentId = computed(() => {
    return localStorage.getItem('studentId')
})

const className = computed(() => {
    return localStorage.getItem('className')
})

const studentName = computed(() => {
    return localStorage.getItem('studentName')
})

const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})

const GetTypeName = (type) => {
    switch (type) {
        case 0:
            return '葷';
        case 1:
            return '素';
        default:
            return '';
    }
}

const getDishData = async () => {
    const formData = new FormData();
    formData.append("date", dayData.value.toDateString());
    const res = await getDataListByDate(formData);
    if (res.data.status) {
        await setCurrentWeek(res.data.data);
    }
}

const ParentList = ref([])
const getParent = async () => {
    const formData = new FormData();
    formData.append("userId", user.value.id);
    formData.append("studentId", studentId.value);
    const res = await getDetail(formData);
    if (res.data.status) {
        ParentList.value = res.data.data;
    }
}

const workDays = ref([])
const setCurrentWeek = async (items) => {
    workDays.value = await Promise.all(items.map(async (item) => {
        const formattedDate = formatDate(item.dishDate);
        const formData = new FormData();
        formData.append("DishId", item.id);
        const dishRes = await getDataListByDishId(formData);
        let selectedFoods = [];
        if (!dishRes.data.status) return null;
        const dishes = dishRes.data.data;
        for (const dish of dishes) {
            const formData1 = new FormData();
            formData1.append("id", dish.foodId);
            const foodRes = await getFoodDetail(formData1);

            if (foodRes.data.status) {
                selectedFoods.push(foodRes.data.data);
            }
        }

        return {
            id: item.id,
            date: formattedDate,
            dayName: item.dishName,
            menu: await generateMenu(),
            selectedFoods: selectedFoods
        };
    }));

    workDays.value.sort((a, b) => new Date(a.date) - new Date(b.date));
    calculateTotalPoints();
};

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
};

const calculateTotalPoints = () => {
    totalPoints.value = workDays.value.reduce((total, day) => {
        return total + day.selectedFoods.reduce((dayTotal, food) => {
            return dayTotal + (food ? parseInt(food.point) : 0)
        }, 0)
    }, 0)
}

const generateMenu = async () => {
    if (FoodList.value.length === 0) {
        const res = await getFoodDataList()
        if (res.data.status) {
            FoodList.value = res.data.data
        }
    }
    return FoodList.value
}

const modalStatus = ref(false)
const currentOrder = ref({
    day: null,
    totalPoint: 0
});
const Order = async (day) => {
    modalStatus.value = true;
    const Point = day.selectedFoods.reduce((dayTotal, food) => {
        return dayTotal + (food && food.point ? parseInt(food.point) : 0);
    }, 0);

    currentOrder.value = {
        day: day,
        totalPoint: Point
    };
}

const Confirm = async () => {
    if (ParentList.value.point < currentOrder.value.totalPoint) {
        alert(`您目前點數:${ParentList.value.point}不足支付${currentOrder.value.totalPoint}`)
    }
    else {
        var NewPoint = ParentList.value.point - currentOrder.value.totalPoint;
        const formData = new FormData();
        formData.append("userId", user.value.id);
        formData.append("studentId", studentId.value);
        formData.append("Nowpoint", ParentList.value.point);
        formData.append("point", NewPoint);
        const res = await editParentForPoint(formData);
        if (res.data.status) {
            const formData1 = new FormData();
            formData1.append("parentId", user.value.id);
            formData1.append("studentId", studentId.value);
            formData1.append("dishId", currentOrder.value.day.id);
            const res1 = await CreateOrderRecord(formData1);
            if (res1.data.status) {
                alert('訂購成功')
            }
            else {
                alert(res1.data.message)
            }
        }
        else {
            alert(res.data.message)
        }
    }
    modalStatus.value = false
    init()
}

const cancel = () => {
    modalStatus.value = false
}
provide('cancel', cancel)

const checkOrderStatus = async (day) => {
    if (!day || !day.id) {
        console.error('Invalid day object:', day);
        return;
    }

    const formData = new FormData();
    formData.append("parentId", user.value.id);
    formData.append("studentId", studentId.value);
    formData.append("dishId", day.id);

    try {
        const res = await getOrderRecordByStudentId(formData);
        if (res.data && res.data.status) {
            orderStatus[day.id] = Boolean(res.data.data);
        } else {
            console.warn('Unexpected API response:', res);
            orderStatus[day.id] = false;
        }
    } catch (error) {
        console.error('檢查訂單狀態失敗:', error);
        orderStatus[day.id] = false;
    }
};

const init = async () => {
    apiLoading.value = true
    await getDishData()
    await getParent()
    for (const day of workDays.value) {
        await checkOrderStatus(day);
    }
    apiLoading.value = false
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}


</script>