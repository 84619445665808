<template>
    <div 
        class="shadow-style-1 transition-all text-green-600 sticky w-full h-[8vh] top-0 left-0 z-10 flex flex-wrap justify-around items-center bg-[rgb(255,255,255,1)]"
    >
        <div 
            v-show="route.path !== '/' && route.path !== '/home'"
            @click="toback"
            class="absolute w-[auto] h-[8vh] top-[0px] left-[24px] md:left-[20px] mine-flex-center cursor-pointer">
            <el-icon :size="isMobile ? '25' : '40'"><Back /></el-icon>
        </div>
        <!-- <div 
            v-if="setStatus"
            class="absolute w-[auto] h-[8vh] top-[0px] right-[5px] mine-flex-center">
            <div v-if="isSchool" @click="tocontactSet" class="px-1">
                <el-icon size="25" color="#fff"><Setting /></el-icon>
            </div>
        </div> -->
        <div 
            class="relative w-[auto] h-[8vh] text-xl md:text-3xl flex flex-wrap justify-center items-center font-extrabold"
            @click="tohome"
            >
            <div class="w-auto h-auto flex flex-wrap justify-center items-center" v-if="headerTitle == '智光智慧園管理平台'">
                <img class="h-[8vh]" src="@/assets/img/ZUGANLogo.png" alt="">
                <img class="h-[8vh] sm:flex hidden" src="@/assets/img/1715094207048.png" alt="">
            </div>
            <div v-else>{{ headerTitle }}</div>
        </div>
        <div 
            v-if="!isMobile" 
            class="relative w-[auto] h-[8vh] px-4 mine-flex-center"
        >
            <template v-if="isLogin">
                <div class="relative w-[auto] h-[8vh] px-4 mine-flex-center text-xl font-bold cursor-pointer hover:scale-[1.1] hover:text-orange-500 transition-all" 
                    v-for="(item, index) in linkData" :key="index"
                    @click="toLink(item.url)">
                    {{item.text}}
                    <div v-if="item.isAlert && notifyUnReadCount > 0" class="absolute top-[15%] xl:top-[20%] right-[2px] w-[22px] h-[22px] text-white text-xs bg-[#FF0000] rounded-full flex flex-wrap justify-center items-center">
                        {{notifyUnReadCount}}
                    </div>
                </div>
            </template>
            <div v-if="isLogin" id="primary_nav_wrap" class="mx-4 text-xl font-bold cursor-pointer hover:scale-[1.1] transition-all">
                <ul>
                    <li @click.stop="toCenter">
                        <div class="py-[10px] hover:text-orange-500 ">{{ statement }}</div>
                        <ul>
                            <li class="py-[5px] hover:text-orange-500" @click.stop="toProfile">個人檔案</li>
                            <li v-if="!isSchool" class="py-[5px] hover:text-orange-500" @click.stop="toStudentQR">學生管理</li>
                            <li v-if="isSchool" class="py-[5px] hover:text-orange-500" @click.stop="toSignInOut">簽到簽退</li>
                            <li v-if="isSchool" class="py-[5px] hover:text-orange-500" @click.stop="toReviewParent">審核學生</li>
                            <li v-if="isSchool" class="py-[5px] hover:text-orange-500" @click.stop="openAnnounce">家長接送</li>
                            <li class="py-[5px] hover:text-orange-500" @click.stop="logout">登出</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div v-else @click="toLogin" class="relative w-[auto] h-[8vh] px-4 mine-flex-center text-xl font-bold cursor-pointer hover:scale-[1.1] transition-all hover:text-orange-500">登入</div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable*/
import { testLogout } from '@/api/api'
import { ref,computed,watch } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import signalR from '@/utils/signalR'

const store = useStore()
const router = useRouter()
const route = useRoute()

const isMobile = computed(() => {
    return store.state.isMobile
})

const isLogin = computed(() => {
    if(store.state.isLogin && user){
        return store.state.isLogin
    }
    return false
})

const roleID = computed(() => {
    return store.state.roleID
})

const user = computed(() => {
    return store.state.user
})

const statement = computed(() => {
    return (user.value ? user.value.roleName + '：' + user.value.name : '')
})

const isSchool = computed(() => {
    return (user.value.roleType == 3) || (user.value.roleType == 2)
})

const setStatus = computed(() => {
    return ((route.path == '/contact') || (route.path == '/contactDetail'))  && isMobile.value
})

const notifyUnReadCount = computed(() => {
    return store.state.notifyUnReadCount
})

const notifyList = computed(() => {
    return store.state.notifyList
})


const toStudentQR = () => {
    router.push({ path: '/studentQR' })
}

const toSignInOut = () => {
    router.push({ path: '/signInOut' })
}

const toReviewParent = () => {
    router.push({ path: '/reviewParentView' })
}


const openAnnounce = () => {
    store.commit('changeAnnounceStatus',true)
}

const headerTitle = ref('智光智慧園管理平台')
watch(route, (newVal, oldval) => {
    if(newVal.path == '/center'){
        headerTitle.value = '個人中心'
    }else if(newVal.path == '/introduction'){
        headerTitle.value = '單位介紹'
    }else if(newVal.path == '/editIntroduction'){
        headerTitle.value = '編輯單位介紹'
    }else if(newVal.path == '/profile'){
        if(newVal.query?.UserID){
            headerTitle.value = '學員檔案'
        }else{
            headerTitle.value = '個人檔案'
        }
    }else if(newVal.path == '/user'){
        headerTitle.value = '個人檔案'
    }else if(newVal.path == '/news'){
        if(newVal.query?.NewsID){
            headerTitle.value = '公告詳情'
        }else{
            headerTitle.value = '公告列表'
        }
    }else if(newVal.path == '/editNews'){
        headerTitle.value = '編輯公告'
    }else if(newVal.path == '/course'){
        headerTitle.value = '課程管理'
    }else if(newVal.path == '/courseDetail'){
        headerTitle.value = newVal.query?.CLSNO
    }else if(newVal.path == '/signRecord'){
        headerTitle.value = '簽到記錄'
    }else if(newVal.path == '/contact'){
        headerTitle.value = '全校電子聯絡簿'
    }else if(newVal.path == '/contactDetail'){
        headerTitle.value = '電子聯絡簿'
    }else if(newVal.path == '/contactSet'){
        headerTitle.value = '設定'
    }else if(newVal.path == '/contactChart'){
        headerTitle.value = '聯絡簿統計'
    }else if(newVal.path == '/medicationList'){
        headerTitle.value = '餵藥'
    }else if(newVal.path == '/signView'){
        headerTitle.value = '聯絡簿簽名'
    }else if(newVal.path == '/transmitView'){
        headerTitle.value = '當日通知單'
    }else if(newVal.path == '/learnSituation'){
        headerTitle.value = '學習狀況'
    }else if(newVal.path == '/tipView'){
        if(isSchool.value){
            headerTitle.value = '編輯活動紀錄'
        }else{
            headerTitle.value = '活動紀錄'
        }
    }else if(newVal.path == '/temperatureView'){
        headerTitle.value = '體溫檢查'
    }else if(newVal.path == '/chatView'){
        headerTitle.value = '個別留言'
    }else if(newVal.path == '/chatroom'){
        headerTitle.value = '個別留言'
    }else if(newVal.path == '/messageView'){
        headerTitle.value = '訊息通知'
    }else if(newVal.path == '/studentScan'){
        headerTitle.value = '學生管理'
    }else if(newVal.path == '/signInScan'){
        headerTitle.value = '簽到掃描'
    }else if(newVal.path == '/signOutScan'){
        headerTitle.value = '簽退掃描'
    }else if(newVal.path == '/recordView'){
        headerTitle.value = '簽到記錄'
    }else if(newVal.path == '/latestNewsView'){
        headerTitle.value = '最新消息'
    }else{
        headerTitle.value = '智光智慧園管理平台'
    }
});

const tohome = () => {
  router.push({ path: '/' })
}

const linkData = computed(() => {
    if ((user.value) && ((user.value.roleType == 3) || (user.value.roleType == 2) || (user.value.roleType == 5))) {
        return [
            {text:"聯絡簿",url:'/contact'},
            {text:"訊息通知",url:'/messageView',isAlert:true},
        ]
    }
    return [
        {text:"訊息通知",url:'/messageView',isAlert:true},
    ]
})

const toLink = (url) => {
    router.push({ path: url })
}

const toback = () => {
    router.go(-1)
}

const toProfile = () =>{
    router.push({ path: '/profile' })
}

const tocontactSet = () =>{
    router.push({ path: '/contactSet' })
}

const toCenter = () => {
    router.push({ path: '/center' })
}

const tolatest = () =>  {
    router.push({ path: '/latestNewsView' })
}

const toLogin = () => {
    router.push({ path: '/login' })
}

const logout = async() => {
    await testLogout().then((res) => {
    }).catch((err) => { })
    store.commit('clearToken')
    router.push({ path: '/' })
}

const formatDate = (dateTime) => {
    let toDay = new Date(Date.now())
    let date = new Date(dateTime)
    let year = date.getFullYear()
    let month = date.getMonth() < 10 ? '0' + date.getMonth() : '' + date.getMonth()
    let day = date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate()
    let dateStr = year + '/' + month + '/'+ day
    if ((toDay.getFullYear() == year) && 
        (toDay.getMonth() == month) && 
        (toDay.getDate() == day)) {
        dateStr = '今天'
    }
    let AM = date.getHours() < 12 ? '上午' : (date.getHours() < 18 ? '下午' : '晚上')
    let hours = date.getHours() < 12 ? date.getHours() : date.getHours() - 12
    hours = hours < 10 ? '0' + hours : '' + hours
    let mins = date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes()
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : '' + date.getSeconds()
    return (dateStr + ' ' + AM + ' ' + hours + ':' + mins)
    // return (AM + ' ' + hours + ':' + mins + ':' + seconds)
}

if (isLogin.value)
{
    signalR.notifyHub.start().then(() => {
        console.log('notifyHub start') 
        signalR.notifyHub.invoke('GetNotifyHistory').then((res) => {
            console.log('GetNotifyHistory')
        }).catch((err) => {
            console.error(err) 
        })
    })

    signalR.notifyHub.on('NotifyHistory', (res) => {
        console.log('NotifyHistory', res)
        let list = []
        for(let key in res){
            let date = JSON.parse(JSON.stringify(res[key].createDateTime))
            let data = JSON.parse(JSON.stringify(res[key]))
            data.createDateTime = formatDate(date)
            list.push(data)
        }
        store.commit('setNotifyList', list)
    })

    signalR.notifyHub.on('ReceiveNotify', (res) => {
        console.log('ReceiveNotify', res)
        let list = JSON.parse(JSON.stringify(notifyList.value))
        let date = JSON.parse(JSON.stringify(res.createDateTime))
        let data = JSON.parse(JSON.stringify(res))
        data.createDateTime = formatDate(date)
        list.unshift(data)        
        store.commit('setNotifyList', list)
    })

    signalR.notifyHub.on('NotifyIsRead', (res) => {
        console.log('NotifyIsRead', res)
        let list = JSON.parse(JSON.stringify(notifyList.value))
        for(let key in list){
            if(list[key].id == res){
                list[key].isRead = true
                break
            }
        }
        store.commit('setNotifyList', list)
    })

    signalR.notifyHub.on('NotifyIsClear', (res) => {
        console.log('NotifyIsClear', res)
        let list = JSON.parse(JSON.stringify(notifyList.value))
        let index = -1;
        for(let key in list){
            if(list[key].id == res){
                index = list.indexOf(list[key]);
                break
            }
        }
        if(index != -1){
            const x = list.splice(index, 1);
            store.commit('setNotifyList', list)
        }
    })
}
</script>

<style lang="scss" scoped>
    .flex-grow {
        flex-grow: 1;
    }
    #primary_nav_wrap
    {
      width: auto;
      height: auto;
    }

    #primary_nav_wrap ul
    {
        list-style:none;
        position:relative;
        float:left;
        margin:0;
        padding:0
    }

    //#primary_nav_wrap ul a
    //{
    //    display:block;
    //    color:#333;
    //    text-decoration:none;
    //    font-weight:700;
    //    font-size:12px;
    //    line-height:32px;
    //    padding:0 15px;
    //    font-family:"HelveticaNeue","Helvetica Neue",Helvetica,Arial,sans-serif
    //}

    #primary_nav_wrap ul li
    {
        position:relative;
        float:left;
        //margin:0;
        //padding:0
    }

    #primary_nav_wrap ul li.current-menu-item
    {
        background:rgba(240, 240, 240, 0.9);
    }

    //#primary_nav_wrap ul li:hover
    //{
    //    background:#b0afaf
    //}

    #primary_nav_wrap ul ul
    {
        display:none;
        position:absolute;
        top:100%;
        left:0;
        background:rgba(240, 240, 240, 0.9);
        padding:0
    }

    #primary_nav_wrap ul ul li
    {
        float:none;
        width:150px;
    }

    //#primary_nav_wrap ul ul a
    //{
    //    line-height:120%;
    //    padding:10px 15px
    //}

    #primary_nav_wrap ul ul ul
    {
        top:0;
        left:100%
    }

    #primary_nav_wrap ul li:hover > ul
    {
        display:block
    }

    
</style>