<template>
  <div v-if="isMobile"
    class="transition-all fixed h-auto w-full py-1 bottom-0 left-0 z-10 flex flex-wrap justify-around items-center bg-[#f9f7f0]">
    <template v-if="isLogin">
      <div
        class="relative w-[25%] h-[8vh] md:w-[25%] md:h-[8vh] text-xs flex flex-col justify-center items-center font-extrabold cursor-pointer"
        @click="tohome()">
        <el-icon :size="25">
          <HomeFilled />
        </el-icon>
        首頁
      </div>
      <div
        class="relative w-[25%] h-[8vh] md:w-[25%] md:h-[8vh] text-xs flex flex-col justify-center items-center font-extrabold cursor-pointer"
        @click="tocontact()">
        <template v-if="(user.roleType == 3) || (user.roleType == 2) || (user.roleType == 5)" >
          <el-icon :size="25">
            <Document />
          </el-icon>
          聯絡簿
        </template>
      </div>
      <div
        class="relative w-[25%] h-[8vh] md:w-[25%] md:h-[8vh] text-xs flex flex-col justify-center items-center font-extrabold cursor-pointer"
        @click="toMessage()">
        <el-icon :size="25">
          <Message />
        </el-icon>
        訊息通知
        <div v-if="notifyUnReadCount > 0" 
          class="absolute w-[20px] h-[20px] top-[5%] left-[calc(50%_+_10px)] text-white text-xs font-normal bg-[#FF0000] rounded-full flex flex-wrap justify-center items-center">
          {{notifyUnReadCount}}
        </div>
      </div>
      <div
        class="relative w-[25%] h-[8vh] md:w-[25%] md:h-[8vh] text-xs flex flex-col justify-center items-center font-extrabold cursor-pointer"
        @click="tocenter()">
        <el-icon :size="25">
          <User />
        </el-icon>
        個人中心
      </div>
    </template>
    <template v-else>
      <div
        class="relative w-[25%] h-[8vh] md:w-[25%] md:h-[8vh] text-xs flex flex-col justify-center items-center font-extrabold cursor-pointer"
        @click="toLogin">
        <el-icon :size="25">
          <User />
        </el-icon>
        請先登入
      </div>
    </template>
  </div>
  <footer v-if="isHome" class="mt-3 bg-success text-white px-1 pb-8">
    <div class="container mx-auto px-4 md:px-28">
        <div class="grid grid-cols-3 gap-8 py-8 text-center justify-items-center">
            <div class="sitemap">
                <h5 class="font-bold text-2xl">網站導覽</h5>
                <ul class="text conter">
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Polygon 5.png" class="h-4 w-4 mr-2" alt="">
                        <a href="#" class="text-white">關於我們</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Polygon 5.png" class="h-4 w-4 mr-2" alt="">
                        <a href="#" class="text-white">功能介紹</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Polygon 5.png" class="h-4 w-4 mr-2" alt="">
                        <a href="#" class="text-white">價格方案</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Polygon 5.png" class="h-4 w-4 mr-2" alt="">
                        <a href="#" class="text-white">聯絡我們</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Polygon 5.png" class="h-4 w-4 mr-2" alt="">
                        <a href="#" class="text-white">幼兒園專欄</a>
                    </li>
                </ul>
            </div>

            <div>
                <h5 class="font-bold text-2xl" style="margin-right: 4rem;">公司資訊</h5>
                <ul class="text conter">
                    <li class="font-bold text-base flex mt-2"><small>名稱: 智光電腦股份有限公司</small></li>
                    <li class="font-bold text-base flex mt-2"><small>電話: 04-23149911</small></li>
                    <li class="font-bold text-base flex mt-2"><small>E-mail: bryte.edu@gamil.com</small></li>
                </ul>
            </div>

            <div class="aboutus">
                <h5 class="font-bold text-2xl">關注我們</h5>
                <ul>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/facebook.png" class="h-5 w-5 mr-2" alt="">
                        <a href="#" class="text-white">Facebook</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/instagram.png" class="h-5 w-5 mr-2" alt="">
                        <a href="#" class="text-white">Instagram</a>
                    </li>
                    <li class="font-bold text-base flex  mt-2">
                        <img src="@/assets/img/line.png" class="h-5 w-5 mr-2" alt="">
                        <a href="#" class="text-white">Line</a>
                    </li>
                    <li class="font-bold text-base flex mt-2">
                        <img src="@/assets/img/Youtube.png" class="h-5 w-5 mr-2" alt="">
                        <a href="#" class="text-white">Youtube</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </footer>
  <div v-if="isMobile"
    class="h-[8vh] w-full py-1">
  </div>
</template>

<script setup>
/*eslint-disable*/
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
const store = useStore()
const router = useRouter()

const isMobile = computed(() => {
  return store.state.isMobile
})

const isLogin = computed(() => {
  return store.state.isLogin
})

const user = computed(() => {
    return store.state.user
})
// const toLink = (val) => {
//   router.push({ path: menuList.value[val].url })
//   store.commit('setMenu',false)
// }


const route = useRoute()

const isHome = computed(() => {
    return (route.path == '') || (route.path == '/') || (route.path == '/home')
})

const tohome = () => {
  router.push({ path: '/' })
}

const tocenter = () => {
  router.push({ path: '/center' })
}

const tocontact = () => {
  if ((user.value.roleType == 3) || (user.value.roleType == 2) || (user.value.roleType == 5)) {
    router.push({ path: '/contact' })
  }
}

const toMessage = () => {
  router.push({ path: '/messageView' })
}

const toLogin = () => {
  router.push({ path: '/login' })
}

const notifyUnReadCount = computed(() => {
    return store.state.notifyUnReadCount
})

</script>

<style lang="scss" scoped>
.flex-grow {
  flex-grow: 1;
}

#primary_nav_wrap {
  width: 100%;
  height: auto;
}

#primary_nav_wrap ul {
  list-style: none;
  position: relative;
  float: left;
  margin: 0;
  padding: 0
}

//#primary_nav_wrap ul a
//{
//    display:block;
//    color:#333;
//    text-decoration:none;
//    font-weight:700;
//    font-size:12px;
//    line-height:32px;
//    padding:0 15px;
//    font-family:"HelveticaNeue","Helvetica Neue",Helvetica,Arial,sans-serif
//}

#primary_nav_wrap ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0
}

#primary_nav_wrap ul li.current-menu-item {
  background: #ddd
}

//#primary_nav_wrap ul li:hover
//{
//    background:#b0afaf
//}

#primary_nav_wrap ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  padding: 0
}

#primary_nav_wrap ul ul li {
  float: none;
  width: 200px
}

//#primary_nav_wrap ul ul a
//{
//    line-height:120%;
//    padding:10px 15px
//}

#primary_nav_wrap ul ul ul {
  top: 0;
  left: 100%
}

#primary_nav_wrap ul li:hover>ul {
  display: block
}
</style>