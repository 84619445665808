<template>
    <div class="w-auto h-auto md:h-[80%] p-2 text-sm md:text-xl flex flex-col justify-start items-center ">
        <dateSelect :apiLoading="apiLoading" :date="dayData" @changeDate="changeDate"></dateSelect>
        <div
            class="w-full max-w-[800px] rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-center">
            <div v-for="(item, index) in list" :key="index"
                class="w-full py-1 flex flex-wrap justify-center items-center">
                <div class="grow shrink w-4-12">{{ item.name + '班' }}</div>
                <div class="grow shrink w-4-12 text-right flex flex-wrap justify-right items-right">
                    <div class="w-[auto] bg-[#20B2AA] text-white py-[1px] px-[2px] rounded">已讀</div>
                    <div class="px-1">{{ item.isRead + '人' }}</div>
                </div>
                <div class="grow shrink w-4-12 text-right flex flex-wrap justify-right items-right">
                    <div class="w-[auto] bg-[#4169E1] text-white py-[1px] px-[2px] rounded">已簽</div>
                    <div class="px-1">{{ item.signature + '人' }}</div>
                </div>
            </div>
        </div>
        <div class="w-[250px] h-[150px] md:w-[750px] md:h-[450px]">
            <Bar v-if="!apiLoading" :data="data" :options="options" />
        </div>
        <template v-for="(item, index) in memberList" :key="index">
            <div class="w-full max-w-[800px] rounded-lg p-1 flex flex-wrap items-center justify-center">
                <div
                    class="collapse w-full bg-slate-50 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                    <input type="checkbox" />
                    <div class="collapse-title" style="padding-inline-end: 1rem;">
                        <div
                            class="w-full max-w-[800px] rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-center">
                            <div class="px-1">{{ item.className + '班' }}</div>
                        </div>
                    </div>
                    <div class="collapse-content">
                        <div
                            class="w-full max-w-[800px] rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-center">
                            <div v-for="(student, studentIndex) in item.students"
                               :key="studentIndex"
                                class="w-full my-1 flex flex-wrap items-center justify-between">
                                <div class="flex flex-wrap items-center justify-center">
                                    <div class="px-1">{{ item.className + '班' }}</div>
                                    <div class="px-1">{{ student.studentNumber + '號' }}</div>
                                    <div class="px-1">{{ student.studentUserName }}</div>
                                </div>
                                <div class="flex flex-wrap items-center justify-center">
                                    <div :class="student.isRead ? 'bg-[#20B2AA]' : 'bg-[#808080]'"
                                        class="w-[student] mx-1 text-sm md:text-xl text-white py-1 px-2 rounded">
                                        {{ student.isRead ? '已讀' : '未讀' }}
                                    </div>
                                    <div :class="student.signature ? 'bg-[#4169E1]' : 'bg-[#808080]'"
                                        class="w-[auto] mx-1 text-sm md:text-xl text-white py-1 px-2 rounded">
                                        {{ student.signature ? '已簽' : '未簽' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";
import { ref, computed } from 'vue'
import { useRouter } from "vue-router";
import { getContactBookRecordByClassId } from '@/api/api'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import dateSelect from '@/components/dateSelect.vue'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const router = useRouter()
const store = useStore()

const memberList = ref([]);
const list = ref([]);
const classId = computed(() => {
    return localStorage.getItem('classId')
})
const className = computed(() => {
    return localStorage.getItem('className')
})
const getData = async () => {
    let formData = new FormData()
    formData.append("classId", classId.value);
    formData.append("date", dayData.value.toDateString());

    let recordResponse = await getContactBookRecordByClassId(formData);
    if (recordResponse && recordResponse.data && recordResponse.data.data) {
        let classData = {
            classId: classId.value,
            className: className.value,
            students: recordResponse.data.data
        };
        const readCount = classData.students.filter(student => student.isRead).length;
        const signatureCount = classData.students.filter(student => student.signature).length;
        list.value = [{
            name: classData.className,
            isRead: readCount,
            signature: signatureCount
        }];
        memberList.value = [classData];
        updateChartData(memberList.value);
    }
    else {
        list.value = [];
        memberList.value = [];
    }
}

const updateChartData = (allRecords) => {
    const labels = [];
    const readData = [];
    const signData = [];

    allRecords.forEach(classData => {
        labels.push(classData.className);

        const readCount = classData.students.filter(student => student.isRead).length;
        const signCount = classData.students.filter(student => student.signature).length;

        readData.push(readCount);
        signData.push(signCount);
    });

    data.value = {
        labels: labels,
        datasets: [
            {
                label: '已讀',
                backgroundColor: '#20B2AA',
                data: readData,
            },
            {
                label: '已簽',
                backgroundColor: '#4169E1',
                data: signData,
            },
        ],
    };
};

const data = ref({
    labels: [],
    datasets: [
        {
            label: '已讀',
            backgroundColor: '#20B2AA',
            data: [],
        },
        {
            label: '已簽',
            backgroundColor: '#4169E1',
            data: [],
        },
    ],
});
const options = ref({});
const apiLoading = ref(false)
const init = async () => {
    apiLoading.value = true

    options.value = {
        maintainAspectRatio: true,
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                min: 0,
                max: 15,
                ticks: {
                    reverse: false,
                    stepSize: 5
                },
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'right',
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',

            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        //console.log('context',context)
                        if (label) {
                            label += context.formattedValue + '%';
                        }
                        return label;
                    },
                }
            }
        },
    };

    apiLoading.value = false
    await getData()
}

const dayData = ref(new Date())
init()
const changeDate = (value) => {
    dayData.value = value
    init()
}

const isMobile = computed(() => {
    return store.state.isMobile
})


</script>

<style></style>