import request from '@/api/index'

export const getNewsSearch = (data) => {
  const url = '/news/search'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getIntroduction = (data) => {
  const url = '/department?DepartmentID=' + data.DepartmentID
  const method = 'get'
  return request({
    method,
    url,
    data,
  })
}

export const getNew = (data) => {
  const url = '/news?NewsID=' + data.NewsID
  const method = 'get'
  return request({
    method,
    url,
    data,
  })
}

export const getUserMenu = (data) => {
  const url = '/CetUser/UserMenu?UserID=' + data.UserID
  const method = 'get'
  return request({
    method,
    url,
    data,
  })
}

export const getProfile = (data) => {
  const url = '/User/Profile'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const EditUser = (data) => {
  const url = '/User/Edit'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}


export const getUserList = (data) => {
  const url = '/CetUser/Search'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getClassList = (data) => {
  const url = '/Course/Calendar'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getClassDetail = (data) => {
  const url = '/Course/Flow?DepartmentID='+data.DepartmentID+'&CLSNO='+data.CLSNO+'&HDYDATE='+data.HDYDATE+'&STUID='+data.STUID
  const method = 'get'
  return request({
    method,
    url,
    data,
  })
}

export const getSignRecord = (data) => {
  const url = '/Course/GetSignRecordBySTUID'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const testLogin = (data) => {
  const url = '/Auth/Login'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const testLogout = () => {
  const url = '/Auth/Logout'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const authorize = () => {
  const url = '/Auth/Authorize'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const registerApi = (data) => {
  const url = '/Auth/Register'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const qrCodeSignIn = (data) => {
  const url = '/QRCode/SignIn'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const qrCodeSignOut = (data) => {
  const url = '/QRCode/SignOut'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const qrCodeBindStudent = (data) => {
  const url = '/QRCode/BindStudent'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getLineInformation = () => {
  const url = '/Auth/LineLogin'
  const method = 'get'
  return request({
    method,
    url,
  })
}

export const getLineLoginCallback = (data) => {
  const url = '/Auth/LineLoginCallback'+addToUrl(data)
  const method = 'get'
  return request({
    method,
    url,
    data
  })
}


export const getLineInformationBindAccount = () => {
  const url = '/Auth/LineLoginBindAccount'
  const method = 'get'
  return request({
    method,
    url,
  })
}

export const editLineInformationBindAccount = (data) => {
  const url = '/Auth/LineLoginBindAccount'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getCaptcha = () => {
  const url = '/Auth/Captcha'
  const method = 'get'
  return request({
    method,
    url,
  })
}

export const getCall = () => {
  const url = '/Pickup/Monitor'
  const method = 'post'
  return request({
    method,
    url,
  })
}

const addToUrl = (payload) => {
  let str = '?'
  for(let key in payload){
      str += key + '=' + payload[key] + '&'
  }
  str = str.substring(0,str.length-1)
  return str
}

export const getContactBookClassList = () => {
  const url = '/ContactBook/GetClassList'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const getContactBookStudentList = () => {
  const url = '/ContactBook/GetStudentList'
  const method = 'post'
  return request({
    method,
    url,
  })
}


export const getMedicationRemindByClassIdAndStudentId = (data) => {
  const url = '/MedicationRemind/GetDataByClassIdAndStudentIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const createMedicationRemind = (data) => {
  const url = '/MedicationRemind/Create'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editMedicationRemind = (data) => {
  const url = '/MedicationRemind/Edit'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getMedicationRemindRecordListByClass = (data) => {
  const url = '/MedicationRemindRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getMedicationRemindRecordListById = (data) => {
  const url = '/MedicationRemindRecord/GetDataListById'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editMedicationRemindRecord = (data) => {
  const url = '/MedicationRemindRecord/Edit'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}
export const editAllBodyTemperatureRecord = (data) => {
  const url = '/BodyTemperatureRecord/EditAllDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}
export const getBodyTemperatureRecordListByClass = (data) => {
  const url = '/BodyTemperatureRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getBodyTemperatureRecordByClassIdAndStudentId = (data) => {
  const url = '/BodyTemperatureRecord/GetDataByClassIdAndStudentIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const createBodyTemperatureRecordRecord = (data) => {
  const url = '/BodyTemperatureRecord/CreateByDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editBodyTemperatureRecordRecord = (data) => {
  const url = '/BodyTemperatureRecord/BackEdit'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getRollCallListByClassId = (data) => {
  const url = '/RollCall/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editRollCall = (data) => {
  const url = '/RollCall/EditByState'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getLeaveByClassIdAndStudentId = (data) => {
  const url = '/Leave/GetDataByClassIdAndStudentIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editLeave = (data) => {
  const url = '/Leave/EditForParent'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const createLeave = (data) => {
  const url = '/Leave/CreateForParent'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getContactBookRecordByClassId = (data) => {
  const url = '/ContactBookRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getContactBookRecord = (data) => {
  const url = '/ContactBookRecord/Detail'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getContactBookRecordByClassIdAndStudentId = (data) => {
  const url = '/ContactBookRecord/GetDataListByClassIdAndStudentIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editContactBookRecordBySignature = (data) => {
  const url = '/ContactBookRecord/EditSignature'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editContactBookRecordByRead2 = (data) => {
  const url = '/ContactBookRecord/EditForRead2'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editSignature = (data) => {
  const url = '/ContactBookRecord/EditSignature'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getStudentStateRecordByClassId = (data) => {
  const url = '/StudentStateRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getStudentStateRecordByContactBookRecordId = (data) => {
  const url = '/StudentStateRecord/GetDataListByContactBookRecordId'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editStudentStateRecord = (data) => {
  const url = '/StudentStateRecord/EditDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editAllStudentStateRecord = (data) => {
  const url = '/StudentStateRecord/EditAllDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getChatRoomListByClassId = (data) => {
  const url = '/ChatMessage/GetChatRoomListByClassId'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getAnnouncementByClassId = (data) => {
  const url = '/Announcement/GetDataByClassId'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getAnnouncementByClassIdAndDate = (data) => {
  const url = '/Announcement/GetDataByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const createAnnouncement = (data) => {
  const url = '/Announcement/Create'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editAnnouncement = (data) => {
  const url = '/Announcement/Edit'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const uploadFile = (data) => {
  const url = '/File/UploadFile'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const uploadSignature = (data) => {
  const url = '/File/UploadSignature'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getClassSelect = () => {
  const url = '/Select/GetClassSelect'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const getRollCallStateSelect = () => {
  const url = '/Select/GetRollCallStateSelect'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const getRollCallLeaveStateSelect = () => {
  const url = '/Select/GetRollCallLeaveStateSelect'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const getMenuByDateAndClassid = (data) => {
  const url = '/Menu/GetMenuByDateAndClassid'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getLeaveStateSelect = () => {
  const url = '/Select/GetLeaveStateSelect'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const getDetail = (data) => {
  const url = '/Parent/Detail'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const editParentForPoint = (data) => {
  const url = '/Parent/Edit2'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getReviewParent = () => {
  const url = '/Parent/GetReview'
  const method = 'post'
  return request({
    method,
    url,
  })
}

export const editReviewParent = (data) => {
  const url = '/Parent/SetReview'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getClassStudentByClassId = (data) => {
  const url = '/ClassStudent/GetDataListByClassId'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getStudent = (data) => {
  const url = '/Student/Detail'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const editStudent = (data) => {
  const url = '/Student/Edit'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getTransmitByClassIdAndDate = (data) => {
  const url = '/TransmitStateRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getTransmitByClassIdAndStudentIdAndDate = (data) => {
  const url = '/TransmitStateRecord/GetDataByClassIdAndStudentIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getTransmitByContactBookRecordId = (data) => {
  const url = '/TransmitStateRecord/GetDataListByContactBookRecordId'
  const method = 'post'
  return request({
    method,
    url,
    data
  })
}

export const getTransmitStateRecordByClassId = (data) => {
  const url = '/TransmitStateRecord/GetDataListByClassIdAndDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editTransmitStateRecord = (data) => {
  const url = '/TransmitStateRecord/EditDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const editAllTransmitStateRecord = (data) => {
  const url = '/TransmitStateRecord/EditAllDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const InsertAllTransmitStateRecord = (data) => {
  const url = '/TransmitStateRecord/InsertAllDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const InsertTransmitStateRecord = (data) => {
  const url = '/TransmitStateRecord/InsertDataList'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getFormRecord = (data) => {
  const url = '/FormRecord/GetDataListByClassIdAndType'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getFormRecordByStudentId = (data) => {
  const url = '/FormRecord/GetDataByStudentId'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const UpdateFormRecord = (data) => {
  const url = '/FormRecord/UpdateModel'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getDataListByDate = (data) => {
  const url = '/dish/GetDataListByDate'
  const method = 'post'
  return request({
    method,
    url,
    data,
  })
}

export const getDataListByDishId = (data) => {
  const url = '/dishRecord/GetDataListByDishId'
  const method = 'post'
  return request({
      method,
      url,
      data
  })
}

export const getFoodDetail = (data) => {
  const url = '/Food/Detail'
  const method = 'post'
  return request({
      method,
      url,
      data
  })
}

export const getFoodDataList = (data) => {
  const url = '/Food/GetDataList'
  const method = 'post'
  return request({
      method,
      url,
      data
  })
}

export const CreateOrderRecord = (data) => {
  const url = '/OrderRecord/Create'
  const method = 'post'
  return request({
      method,
      url,
      data
  })
}

export const getOrderRecordByStudentId = (data) => {
  const url = '/OrderRecord/GetDataByStudentId'
  const method = 'post'
  return request({
      method,
      url,
      data
  })
}


// .catch((err) => { })