<template>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center" v-if="contactBookList.length > 0">
        <div
            v-for="(item,index) in contactBookList" :key="index"
            :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
            class="w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] bg-indigo-500 rounded-full" @click="toShow(index)" @dblclick="item.isShow = false">
                <img v-if="item.studentUserPictureUrl" :src="item.studentUserPictureUrl" class="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full" alt="">
            </div>
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center" @click="toShow(index)" @dblclick="item.isShow = false">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">學生姓名：{{item.studentUserName}}</div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">座號：{{ item.studentNumber + '號' }}</div>
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">生日：{{ formatDate(item.birthday) }}</div>
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">年齡：{{ calculateAge(item.birthday) + '歲' }}
                    </div>
                </div>
            </div>
            <template v-if="item.isShow">
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">班級：{{item.className}}</div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">座號：{{item.studentNumber}}</div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">身高：{{item.height}}</div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">體重：{{item.weight}}</div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">BMI：{{item.bmi}}</div>
                </div>
                <div class="w-full h-auto rounded-lg m-1 p-1 flex flex-wrap items-start justify-start">
                    <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">頭圍：{{item.headCircumference}}</div>
                </div>
                <div class="w-auto px-1 h-full flex flex-col items-start justify-center" style="max-width: 120;">
                    <img :src="'/api/QRCode/Student?studentId=' + item.studentId" alt="">      
                </div>
            </template>
        </div>
        <div
            class="bg-slate-50 w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                <button
                    @click="toStudentScan()"
                    class="w-full text-white font-bold rounded bg-blue-500 hover:bg-blue-600">
                    綁定學生
                </button>
            </div>
        </div>
    </div>
    <div class="w-auto h-auto p-2 flex flex-col justify-center items-center" v-else>
        <div
            class="bg-slate-50 w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="w-auto px-1 h-full grow flex flex-col items-start justify-center">
                <div class="max-w-[50vw] md:max-w-[18vw] px-1 truncate">尚未有已綁定學生</div>
            </div>
        </div>
        <div
            class="bg-slate-50 w-[95%] md:w-[40%] h-[auto] text-sm md:text-lg rounded-lg bg-slate-50 my-1 px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-end">
            <div class="relative w-full h-auto px-2 md:px-4 text-sm md:text-lg items-center">
                <button
                    @click="toStudentScan()"
                    class="w-full text-white font-bold rounded bg-blue-500 hover:bg-blue-600">
                    綁定學生
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import {ref,computed } from 'vue'
import { useRouter } from "vue-router"
import { getContactBookStudentList } from '@/api/api'

const store = useStore()
const router = useRouter()

const isMobile = computed(() => {
    return store.state.isMobile
})

const contactBookList = ref([])

function calculateAge(birthdayString) {
    const birthday = new Date(birthdayString);
    const today = new Date();

    let age = today.getFullYear() - birthday.getFullYear();
    const monthDiff = today.getMonth() - birthday.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
        age--;
    }

    return age;
}

const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date.getFullYear() === 1911 && date.getMonth() === 0 && date.getDate() === 1) {
        return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
};

const getList = async() => {
  await getContactBookStudentList().then((res) => {
    if(res.data.status){
        contactBookList.value = res.data.data
        for(let i = 0; i < contactBookList.value.length; i++){
            contactBookList.value[i].isShow = false
        }
      }else{
        console.log(res.data.message)
      }
  }).catch((err) => { })
}
getList()

const toShow = (index) => {
    for(let i = 0; i < contactBookList.value.length; i++){
        contactBookList.value[i].isShow = false
        if(i == index){
            contactBookList.value[i].isShow = true
        }
    }
}

const toStudentScan = () => {
    router.push({ path: '/studentScan' })
}
</script>

<style>

</style>