<template>
    <div class="w-[100vw] h-[70vh] text-3xl flex flex-wrap justify-center items-center ">
        找不到此畫面
    </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from "vuex";

import {ref,computed,watch } from 'vue'
import { useRouter,useRoute } from "vue-router";

const store = useStore()
const router = useRouter()
const route = useRoute()

</script>

<style>

</style>