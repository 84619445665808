<template>
    <div class="w-auto h-auto md:h-auto p-2 flex flex-col justify-start items-center ">
        <div
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div class="px-2">To:</div>
            <div class="px-2">{{ className }}班</div>
        </div>
        <div v-if="!isSchool"
            class="w-[95%] md:w-[40%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center justify-start">
            <div>{{ studentName }} {{ studentNumber }}號</div>
        </div>
        <div class="line-style w-[100%] text-slate-800 text-base md:text-xl flex">餵藥紀錄</div>
        <template v-if="!apiLoading">
            <div v-for="(item, index) in list" :key="index"
                :class="(index % 2 == 0) ? 'bg-slate-50' : 'bg-slate-200'"
                class="w-[95%] md:w-[40%] h-[auto] rounded-lg px-1 py-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-col items-center justify-start">
                <div class="w-full py-1 flex flex-wrap items-center justify-start">
                    <div class="px-1">{{ item.medicationTime.substr(0, 5) }}</div>
                    <div class="px-1">{{ item.studentUserName }}</div>
                    <div class="px-1">{{ item.studentNumber }}號</div>
                    <div
                        class="w-[auto] bg-[#4169E1] text-sm md:text-xl text-white py-[1px] px-[2px] rounded mr-[10px]">
                        {{ item.isAfterMeal ? '飯後' : '飯前' }}
                    </div>
                    <div class="w-[auto] bg-[#4169E1] text-sm md:text-xl text-white py-[1px] px-[2px] rounded">
                        {{ item.state ? '已餵藥' : '尚未餵藥' }}
                    </div>

                </div>
                <div class="w-full pl-[20px] pr-1 py-1 flex flex-wrap items-center justify-start">
                    老師回復：{{ item.reply }}
                </div>
                <div class="w-full pl-[20px] pr-1 py-1 flex flex-wrap items-center justify-start">
                    餵藥日期：{{ formatDate(item.scheduleDate) }}
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { getMedicationRemindRecordListById } from '@/api/api'
/* import { useStore } from "vuex"; */
import { ref, computed, /* provide */ } from 'vue'
/* import { useRouter, useRoute } from "vue-router"; */

/* const router = useRouter()
const route = useRoute()
const store = useStore() */

const className = computed(() => {
    return localStorage.getItem('className')
})
const studentName = computed(() => {
    return localStorage.getItem('studentName')
})
const studentNumber = computed(() => {
    return localStorage.getItem('studentNumber')
})
let medicationRemindId = computed(() => {
    return localStorage.getItem('medicationremindid')
})

const temperatureOptions = ref([])
const apiLoading = ref(false)

const list = ref([
    {
        contactBookRecordId: '',
        medicationRemindId: '',
        state: 0,
        reply: '',
        photo: '',
        medicationTime: '00:00:00',
        studentUserName: '',
        isAfterMeal: true,
        medicationRemindRemark: '',
        medicationRemindPhoto: '',
        className: '',
        studentNumber: 0
    },
])

const getList = async () => {
    const formData = new FormData()
    formData.append("id", medicationRemindId.value)

    await getMedicationRemindRecordListById(formData).then((res) => {
        if (res.data.status) {
            list.value = res.data.data.slice().sort((a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate));
        } else {
            console.log(res.data.message)
        }
    })/* .catch((err) => { }) */
}

const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date.getFullYear() === 1911 && date.getMonth() === 0 && date.getDate() === 1) {
        return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
/*  const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
 */
    return `${year}/${month}/${day}`;
};

const init = async () => {
    apiLoading.value = true
    let target = 34.0
    for (let i = 0; i < 60; i++) {
        target += 0.1
        temperatureOptions.value.push({
            value: target.toFixed(1),
            label: target.toFixed(1)
        })
    }
    apiLoading.value = false
    getList()
}

init()
</script>